import React, { useState, useEffect } from "react";
import { Box, Button, useTheme, Fab, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
import Loading from "../loading";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { toast } from "react-toastify";
import { url } from "../../../utils";
import DateTimePicker from "react-datetime-picker";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Timer({ data, setstatus }) {
  const [open, setOpen] = React.useState(false);
  const { spacing } = useTheme();
  const [loading, setloading] = useState(false);
  const [id, setid] = useState("");
  const [timer, settimer] = useState(new Date().getTime());
  const [timer1, settimer1] = useState(new Date());
  const handleClose = () => {
    setOpen(false);
  };

  const timerHandler = async () => {
    setloading(true);
    setstatus(false);
    const { data } = await axios.post(`${url}/admin/timer`, {
      timer: timer,
      _id: id,
    });
    toast.success(data.message);
    setstatus(true);
    setloading(false);
    setOpen(false);
  };
  useEffect(() => {
    if (!Object.keys(data ? data : {}).length == 0) {
      setid(data._id);
    }
  }, [data]);

  return (
    <>
      <Box component="h3" textAlign="left" fontSize={spacing(2)}>
        Timer:
      </Box>
      <Box
        component="h3"
        textAlign="left"
        fontSize={spacing(2)}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width={{ xs: "100%", md: "100%" }}
      >
        {moment(data?.time).format("LLLL")}
        <Fab
          size="medium"
          color="secondary"
          aria-label="edit"
          onClick={() => setOpen(true)}
        >
          <EditIcon />
        </Fab>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="dialoge__content__section">
          <Loading open={loading} />
          <Box position="absolute" right="0%" top="0%" textAlign="right" m={0}>
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ color: "#27CFA7" }} fontSize="small" />
            </IconButton>
          </Box>
          <Box
            component="h3"
            fontFamily="Avenir"
            fontWeight={700}
            fontSize={20}
            textAlign="center"
            my={2}
          >
            Timer Setting
          </Box>
          <Box mb={spacing(3)} mt={spacing(0.5)}>
            <DateTimePicker
              onChange={(e) => {
                settimer(moment(e).format("x"));
                settimer1(e);
              }}
              value={timer1}
              className="inputdatefield"
            />
          </Box>
          <Box my={spacing(0.5)} align="center">
            <Button
              style={{
                backgroundColor: "#69FFDB",
                fontWeight: 700,
                height: "2rem",
              }}
              width={{ xs: "100%", md: "40%" }}
              fontWeight={700}
              onClick={timerHandler}
            >
              Update
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
