import React, { useState, useEffect } from "react";
import { Box, Button, useTheme, Fab, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
import Loading from "../loading";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { toast } from "react-toastify";
import { url } from "../../../utils";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Theme({ data, setstatus }) {
  const [open, setOpen] = React.useState(false);
  var [theme, settheme] = useState("");
  const { spacing } = useTheme();
  const [loading, setloading] = useState(false);
  const [id, setid] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const themeHandler = async () => {
    if (!theme) {
      toast.error("Enter theme title first.");
    } else {
      try {
        setloading(true);
        setstatus(false);
        const { data } = await axios.post(`${url}/admin/theme`, {
          theme,
          _id: id,
        });
        toast.success(data.message);
        setstatus(true);
        setloading(false);
        setOpen(false);
      } catch (error) {
        setloading(false);
        setOpen(false);
        toast.error(error.data.message);
      }
    }
  };
  useEffect(() => {
    if (!Object.keys(data ? data : {}).length == 0) {
      setid(data._id);
      settheme(data.theme);
    }
  }, [data]);

  return (
    <>
      <Box component="h3" textAlign="left" fontSize={spacing(2)}>
        Theme Title:
      </Box>

      <Box
        component="h3"
        textAlign="left"
        fontSize={spacing(2)}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width={{ xs: "100%", md: "100%" }}
      >
        {data?.theme}
        <Fab
          size="medium"
          color="secondary"
          aria-label="edit"
          onClick={() => setOpen(true)}
        >
          <EditIcon />
        </Fab>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent
          position="relative"
          className="dialoge__content__section"
        >
          <Loading open={loading} />
          <Box position="absolute" right="0%" top="0%" textAlign="right" m={0}>
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ color: "#27CFA7" }} fontSize="small" />
            </IconButton>
          </Box>
          <Box
            component="h3"
            fontFamily="Avenir"
            fontWeight={700}
            fontSize={20}
            textAlign="center"
            my={2}
          >
            Theme Text
          </Box>
          <Box my={spacing(0.5)} align="center">
            <input
              style={{
                background: "#FFFFFF",
                border: "2px solid #DD1DE1",
                height: "40px",
                outline: "none",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                padding: "5px",
              }}
              placeholder="Theme title"
              value={theme}
              onChange={(e) => settheme(e.target.value)}
            />
          </Box>
          <Box my={spacing(0.5)} align="center">
            <Button
              style={{
                backgroundColor: "#69FFDB",
                fontWeight: 700,
                height: "2rem",
              }}
              width={{ xs: "100%", md: "40%" }}
              fontWeight={700}
              onClick={themeHandler}
            >
              Update
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
