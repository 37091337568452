import React, { useState, useEffect, useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import { AppContext, url } from "../../../utils";
import { toast } from "react-toastify";
import axios from "axios";
import { Country } from "country-state-city";
import { Box, FormControl, MenuItem, Select } from "@material-ui/core";

import Loading from "../loading";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  select: {
    backgroundColor: "transparent",
    border: "1px solid #fff",
    cursor: "pointer",
    borderRadius: "4px",
    height: "40px",
    margin: "7px 0px",
    padding: "0px 5px",
    "& .MuiSelect-icon": {
      color: "#fff",
    },
    "&:before": {
      borderImage:
        "linear-gradient(113.1deg, #2BE233 3.48%, #FF772A 38.5%, #FF3566 60.53%, #FF0095 78%) 1",
    },
    "&:after": {
      borderImage:
        "linear-gradient(113.1deg, #2BE233 3.48%, #FF772A 38.5%, #FF3566 60.53%, #FF0095 78%) 1",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderImage:
        "linear-gradient(113.1deg, #2BE233 3.48%, #FF772A 38.5%, #FF3566 60.53%, #FF0095 78%) 1",
    },
  },
};

const useStyles = makeStyles(styles);
export default function ShippingDetails() {
  const [countryCode, setcountryCode] = useState("");
  const [countries, setCountries] = useState([]);
  const [country, setcountry] = useState("");

  const [fn, setfn] = useState("");
  const [ln, setln] = useState("");
  const [email, setemail] = useState("");
  const [addr, setaddr] = useState("");
  const [addr1, setaddr1] = useState("");
  const [city, setcity] = useState("");
  const [postcode, setpostcode] = useState("");
  const classes = useStyles();
  const { account } = useContext(AppContext);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);
  useEffect(() => {
    if (countryCode) {
      const { name } = Country.getCountryByCode(countryCode);
      setcountry(name);
    }
  }, [countryCode]);

  useEffect(() => {
    const init = async () => {
      const { data: data2 } = await axios.get(`${url}/user/tickets/${account}`);

      var data = data2.data?.shippingDetails;
      if (!Object.keys(data ? data : {}).length == 0) {
        setfn(data.firstName);
        setln(data.lastName);
        setemail(data.email);
        setaddr(data.address);
        setaddr1(data.address1);
        setcity(data.city);
        setcountry(data.country);
        setcountryCode(data.countryCode);
        setpostcode(data.postcode);
      }
    };
    init();
  }, [account]);
  const detailsHadler = async () => {
    if (!fn) {
      toast.error("Error! please enter firstname");
    } else if (!ln) {
      toast.error("Error! please enter lastname");
    } else if (!email) {
      toast.error("Error! please enter Email");
    } else if (!addr) {
      toast.error("Error! please enter Address");
    } else if (!city) {
      toast.error("Error! please enter city");
    } else if (!country) {
      toast.error("Error! please enter country");
    } else if (!postcode) {
      toast.error("Error! please enter postcode");
    } else {
      try {
        setloading(true);
        const { data } = await axios.post(`${url}/user/shipping-details`, {
          firstName: fn,
          lastName: ln,
          email: email,
          address: addr,
          address1: addr1,
          city: city,
          country: country,
          postcode: postcode,
          userWalletAddress: account,
          countryCode: countryCode,
        });
        if (data.status) {
          toast.success(data.message);
          setloading(false);
          // window.loction.reload();
        } else {
          toast.error(data.message);
          setloading(false);
        }
      } catch (error) {
        toast.error(error.message);
        setloading(false);
      }
    }
  };
  return (
    <GridContainer>
      <Loading loading={loading} />
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Save Shipping Details</h4>
            <p className={classes.cardCategoryWhite}>
              Save and Edit shipping details
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="First Name"
                  id="company-disabled"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  error={!fn}
                  success={fn}
                  value={fn}
                  onChange={(e) => setfn(e.target.value)}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Last Name"
                  id="username"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  error={!ln}
                  success={ln}
                  value={ln}
                  onChange={(e) => setln(e.target.value)}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Email"
                  id="email-address"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  error={!email}
                  success={email}
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Address"
                  id="first-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  error={!addr}
                  success={addr}
                  value={addr}
                  onChange={(e) => setaddr(e.target.value)}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Appartment,  Suit,  etc. (optional)"
                  id="last-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={addr1}
                  onChange={(e) => setaddr1(e.target.value)}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="City"
                  id="city"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  error={!city}
                  success={city}
                  value={city}
                  onChange={(e) => setcity(e.target.value)}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Box mt={3}>
                  {" "}
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ color: "gray" }}
                    >
                      Country/Region
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className={classes.select}
                      value={countryCode}
                      onChange={(e) => setcountryCode(e.target.value)}
                    >
                      {countries.map(({ name, isoCode }, index) => {
                        return (
                          <MenuItem style={{ color: "#fff" }} value={isoCode}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Postcode"
                  id="postal-code"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  error={!postcode}
                  success={postcode}
                  value={postcode}
                  onChange={(e) => setpostcode(e.target.value)}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <Box align="center" py={5}>
            <Button color="primary" onClick={detailsHadler}>
              Save Details
            </Button>
          </Box>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
