import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Snack from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/snackbarContentStyle.js";
import { Box } from "@material-ui/core";
import moment from "moment";
import { MdDelete } from "react-icons/md";

import axios from "axios";
import { AppContext, url } from "../../../utils.js";
import { toast } from "react-toastify";

const useStyles = makeStyles(styles);

export default function SnackbarContent(props) {
  const classes = useStyles();
  const { account } = useContext(AppContext);

  const { message, date, color, close, icon, rtlActive, id, init, del } = props;
  var action = [];
  const messageClasses = classNames({
    [classes.iconMessage]: icon !== undefined,
  });
  if (close !== undefined) {
    action = [
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
      >
        <Close className={classes.close} />
      </IconButton>,
    ];
  }
  const deleteHandler = async () => {
    try {
      // setloading(true);

      const { data } = await axios.post(`${url}/user/delete-status`, {
        id,
        account,
      });

      if (data.status) {
        toast.success(data.message);
        init();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("addToken", error);
    }
  };
  return (
    <Snack
      style={{ position: "relative" }}
      message={
        <div>
          <Box>{moment(date).format("lll")}</Box>
          {del && (
            <Box position="absolute" right="1%" top="2%">
              <IconButton onClick={deleteHandler}>
                <MdDelete style={{ color: "red" }} />
              </IconButton>
            </Box>
          )}
          <br />
          <br />
          <Box display="flex" alignItems="center">
            {icon !== undefined ? (
              <props.icon className={classes.icon} />
            ) : null}

            <Box className={messageClasses}>{message}</Box>
          </Box>
        </div>
      }
      classes={{
        root: classes.root + " " + classes[color],
        message: classes.message,
        action: classNames({ [classes.actionRTL]: rtlActive }),
      }}
      action={action}
    />
  );
}

SnackbarContent.propTypes = {
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf(["info", "success", "warning", "danger", "primary"]),
  close: PropTypes.bool,
  icon: PropTypes.object,
  rtlActive: PropTypes.bool,
};
