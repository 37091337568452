import React, { useState, useEffect } from "react";
import { Box, Button, useTheme, Fab, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
import Loading from "../../views/loading";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { toast } from "react-toastify";
import { url } from "../../../utils";
import { RiMailSendFill } from "react-icons/ri";
import { MdSend } from "react-icons/md";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BullkNotify({ data, setstatus, ids, init }) {
  const [open, setOpen] = React.useState(false);
  const [buybackLock, setbuybackLock] = React.useState(false);
  const [message, setmessage] = React.useState("");

  const { spacing } = useTheme();
  const [loading, setloading] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const NotifyHandler = async () => {
    if (!message) {
      toast.error("Enter notification text first.");
    } else {
      try {
        setloading(true);
        const { data } = await axios.post(`${url}/user/randomNotification`, {
          message: message,
          ids: ids,
        });
        if (data.status) {
          toast.success(data.message);
          init();
          setloading(false);
          setOpen(false);
        } else {
          toast.error(data.message);
          setloading(false);
          setOpen(false);
        }
      } catch (error) {}
    }
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <RiMailSendFill />
      </IconButton>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent
          position="relative"
          className="dialoge__content__section"
        >
          <Loading open={loading} />
          <Box position="absolute" right="0%" top="0%" textAlign="right" m={0}>
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ color: "#27CFA7" }} fontSize="small" />
            </IconButton>
          </Box>
          <Box
            component="h3"
            fontFamily="Avenir"
            fontWeight={700}
            fontSize={20}
            textAlign="center"
            my={2}
          >
            Notification
          </Box>

          <Box my={spacing(0.5)} align="center">
            <textarea
              style={{
                background: "#FFFFFF",
                border: "2px solid #DD1DE1",
                height: "40px",
                outline: "none",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                padding: "5px",
                width: "300px",
                height: "100px",
              }}
              placeholder="Notification Text"
              value={message}
              onChange={(e) => setmessage(e.target.value)}
            />
          </Box>

          <Box my={spacing(0.5)} align="center">
            <Button
              style={{
                backgroundColor: "#69FFDB",
                fontWeight: 700,
                height: "2rem",
              }}
              width={{ xs: "100%", md: "40%" }}
              fontWeight={700}
              onClick={NotifyHandler}
            >
              Send <MdSend />
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
