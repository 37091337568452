import React, { useState, useEffect, useContext } from "react";
import { Box, Button, useTheme, Fab, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
import Loading from "../loading";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { toast } from "react-toastify";
import { url, getTokenContract, AppContext } from "../../../utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BuybackCrypto({ data, setstatus }) {
  const [open, setOpen] = React.useState(false);
  var [file, setfile] = useState("");
  const { signer } = useContext(AppContext);

  const [tokenAddressname, setTokenAddressName] = useState("");
  const [tokenSymbol, settokenSymbol] = useState("");
  const [tokenDecimal, settokenDecimal] = useState("");
  const [tokenAddress, settokenAddress] = useState("");
  const [aggregatorAddress, setaggregatorAddress] = useState("");
  const { spacing } = useTheme();
  const [loading, setloading] = useState(false);
  const [id, setid] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const detailsHandler = async () => {
    if (!tokenAddress) {
      toast.error("please fill all necessary fields");
    } else if (!tokenAddressname) {
      toast.error("Token Address is invalid");
    }
    //  else if (!file) {
    //   toast.error("Please select token logo.");
    // }
    else {
      setloading(true);
      setstatus(false);
      const dataF = new FormData();
      dataF.append("file", file);
      dataF.append("_id", id);
      dataF.append("tokenAddressname", tokenAddressname);
      dataF.append("tokenAddress", tokenAddress);
      dataF.append("tokenSymbol", tokenSymbol);
      dataF.append("tokenDecimal", tokenDecimal);
      dataF.append("aggregatorAddress", aggregatorAddress);
      const { data } = await axios.post(`${url}/admin/details`, dataF);
      toast.success(data.message);
      setstatus(true);
      setloading(false);
      setOpen(false);
    }
  };
  const handleTokenAddress = async (e) => {
    const value = e.target.value.trim();
    settokenAddress(value);
    try {
      if (value) {
        setloading(true);
        const tokenContract = getTokenContract(value, signer);
        const name = await tokenContract.name();
        const symbol = await tokenContract.symbol();
        const decimal = await tokenContract.decimals();
        setTokenAddressName(name);
        settokenSymbol(symbol);
        settokenDecimal(decimal);
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      console.error("handleTokenAddress", error);
    }
  };
  useEffect(() => {
    if (!Object.keys(data ? data : {}).length == 0) {
      setid(data._id);
      setaggregatorAddress(data.tokenAggregator);
      settokenAddress(data.tokenAddress);
      setTokenAddressName(data.tokenName);
      settokenSymbol(data.tokenSymbol);
      settokenDecimal(data.tokenDecimel);
    }
  }, [data]);

  return (
    <>
      <Box component="h3" textAlign="left" fontSize={spacing(2)}>
        Buyback Crypto:
      </Box>
      <Box
        component="h3"
        textAlign="left"
        fontSize={spacing(2)}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width={{ xs: "100%", md: "100%" }}
      >
        <Box display="flex" alignItems="center">
          <img width="20px" src={`${url}/${data?.logoPath}`} alt="" />
          &nbsp;
          {data?.tokenName} {data?.tokenSymbol}
        </Box>
        <Fab
          size="medium"
          color="secondary"
          onClick={() => setOpen(true)}
          aria-label="edit"
        >
          <EditIcon />
        </Fab>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="dialoge__content__section">
          <Loading open={loading} />
          <Box position="absolute" right="0%" top="0%" textAlign="right" m={0}>
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ color: "#27CFA7" }} fontSize="small" />
            </IconButton>
          </Box>
          <Box
            component="h3"
            fontFamily="Avenir"
            fontWeight={700}
            fontSize={20}
            textAlign="center"
            my={2}
          >
            Buyback Crypto Details
          </Box>
          <Box my={spacing(0.2)} align="center">
            <input
              style={{
                background: "#FFFFFF",
                border: "2px solid #DD1DE1",
                height: "40px",
                outline: "none",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                padding: "5px",
              }}
              placeholder="Token Aggregator"
              value={aggregatorAddress}
              onChange={(e) => setaggregatorAddress(e.target.value)}
            />
          </Box>
          <Box my={spacing(0.2)} align="center">
            <input
              style={{
                background: "#FFFFFF",
                border: "2px solid #DD1DE1",
                height: "40px",
                outline: "none",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                padding: "5px",
              }}
              placeholder="Token Address"
              value={tokenAddress}
              onChange={handleTokenAddress}
            />
          </Box>
          <Box my={spacing(0.2)} align="center">
            <input
              style={{
                background: "#FFFFFF",
                border: "2px solid #DD1DE1",
                height: "40px",
                outline: "none",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                padding: "5px",
              }}
              placeholder="Token Name"
              value={tokenAddressname}
              readOnly
            />
          </Box>
          <Box my={spacing(0.2)} align="center">
            <input
              style={{
                background: "#FFFFFF",
                border: "2px solid #DD1DE1",
                height: "40px",
                outline: "none",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                padding: "5px",
              }}
              placeholder="Token Symbol"
              value={tokenSymbol}
              readOnly
            />
          </Box>
          <Box my={spacing(0.2)} align="center">
            <input
              style={{
                background: "#FFFFFF",
                border: "2px solid #DD1DE1",
                height: "40px",
                outline: "none",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                padding: "5px",
              }}
              placeholder="Token Decimal"
              value={tokenDecimal}
              readOnly
            />
          </Box>
          <Box my={spacing(0.5)} align="center">
            <Box
              component="h3"
              fontFamily="Avenir"
              fontWeight={700}
              fontSize={20}
              textAlign="center"
              my={0}
            >
              <img width="20px" src={`${url}/${data?.logoPath}`} alt="" />
            </Box>
            <Box
              component="h3"
              fontFamily="Avenir"
              fontWeight={700}
              fontSize={20}
              textAlign="center"
              my={0}
            >
              Token Logo
            </Box>
            <input
              style={{
                background: "#FFFFFF",
                border: "2px solid #DD1DE1",
                height: "40px",
                outline: "none",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                padding: "5px",
              }}
              type="file"
              onChange={(e) => setfile(e.target.files[0])}
            />
          </Box>
          <Box my={spacing(0.5)} align="center">
            <Button
              style={{
                backgroundColor: "#69FFDB",
                fontWeight: 700,
                height: "2rem",
              }}
              width={{ xs: "100%", md: "40%" }}
              fontWeight={700}
              onClick={detailsHandler}
            >
              Update
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
