import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { url } from "../../../utils";
import TableCell from "@material-ui/core/TableCell";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CircularProgress from "@material-ui/core/CircularProgress";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import { Box, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const { tableHead, tableData, tableHeaderColor, setloading, init, search } =
    props;
  const classes = useStyles();

  const del = async (id) => {
    try {
      setloading(true);

      const { data } = await axios.post(`${url}/product/delete`, { id });

      if (data.status) {
        toast.success(data.message);
        setloading(false);
        init();
      } else {
        toast.error(data.message);
        setloading(false);
        // setaddcoinModal(false);
      }
    } catch (error) {
      setloading(false);
      console.error("addToken", error);
    }
  };
  const lockHandeler = async (id, locked) => {
    try {
      setloading(true);
      const { data } = await axios.post(`${url}/product/locked`, {
        id,
        locked: !locked,
      });
      if (data.status) {
        toast.success(data.message);
        setloading(false);
        init();
      } else {
        toast.error(data.message);
        setloading(false);
        // setaddcoinModal(false);
      }
    } catch (error) {
      setloading(false);
      console.error("addToken", error);
    }
  };
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData?.length !== 0 ? (
            tableData.map(
              (
                {
                  _id,
                  productName,
                  productLCP,
                  productShips,
                  productStock,
                  picPath,
                  methods,
                  locked,
                },
                key
              ) => (
                <TableRow key={key} className={classes.tableBodyRow}>
                  <TableCell className={classes.tableCell} key={key}>
                    {_id}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    <img width="50px" src={`${url}/${picPath}`} alt="" />
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {productName}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {methods.map(({ company, sdays, sprice }) => (
                      <div>
                        {company}, {sdays}, {sprice} $
                      </div>
                    ))}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {productLCP}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {productShips}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {productStock}
                    <Box display="flex" alignItems="center">
                      <Link to={`/admin/edit-product/${_id}`}>
                        <IconButton style={{ padding: "5px" }}>
                          <EditIcon
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              fontSize: "15px",
                            }}
                          />
                        </IconButton>
                      </Link>
                      <IconButton
                        onClick={() => del(_id)}
                        style={{ padding: "5px" }}
                      >
                        <DeleteIcon
                          style={{
                            cursor: "pointer",
                            color: "red",
                            fontSize: "15px",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => lockHandeler(_id, locked)}
                        style={{ padding: "5px" }}
                      >
                        {locked ? (
                          <LockOpenIcon
                            style={{
                              cursor: "pointer",
                              color: "red",
                              fontSize: "15px",
                            }}
                          />
                        ) : (
                          <LockIcon
                            style={{
                              cursor: "pointer",
                              color: "red",
                              fontSize: "15px",
                            }}
                          />
                        )}
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <TableRow className={classes.tableBodyRow}>
              {!search ? (
                <TableCell
                  colSpan="7"
                  align="center"
                  className={classes.tableCell}
                >
                  No product is added yet.
                  <br />
                  <CircularProgress style={{ color: "#9931B1" }} />
                </TableCell>
              ) : (
                <TableCell
                  colSpan="7"
                  align="center"
                  className={classes.tableCell}
                >
                  No product found with "{search}".
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
