export const tokenAddress = "0xF85553FD7e1377B0f25F2Cd7cCBa2Dd1E1DfFC73";
// export const stakeTestingToken = "0x52C2c95822100298Dc090EbB1DB7Cb6a70849d28"; //Testnet
export const stakeTestingToken = "0x7150363247D59F1539C5D2Af5E9b743Be07e2F8B"; //mainnet
// export const LCPtokenAddress = "0x59Fbc215636a4D27051e5c03EbD4FBB720b0Ef0C"; //testnet
export const LCPtokenAddress = "0x33E9CC7A0eedBCB6B4bB8BFa4108f7d1e052dD17"; //mainnet
// export const cryptopartyAddress = "0x3A7ca3D67C74E4e859689e26f0FB150e1e47d54C"; //testnet
export const cryptopartyAddress = "0x9BFAEee94d2274aD543D9F83661eB71EA260d462"; // mainnet
// export const BUSDAddress = "0x97e708944801Ea2CC20039D67E894a5E0951D12F"; //testnet
export const BUSDAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"; //mainnet
// export const presaletoken = "0x3DFA9b7cB5944c3DbA51ed7467F3069909860666"; //mainnet
export const presaletoken = "0x7150363247D59F1539C5D2Af5E9b743Be07e2F8B"; //mainnet
export const presaleContract = "0xd92606118ea92d5004dB58d041A4766F9Aa901B7"; //mainnet
export const recipentAddress = "0xEB6d13D363dB7D1AdA75520B4e9b3CF00e047516";
// export const presaletoken = "0x375f90340A54cca8eb1046D99d3061AaE36EdC00"; //testnet
// export const presaleContract = "0x810AC687740D569FB8AB449989dd809eF1Fc779B"; //testnet
// export const defaultAddress = "0xa48f0D9d0cB30eb087C6589dc804CfEe7f1ed8eD";
// export const defaultAddress = "0xEA9f0c9a4393AcE8cACd21B022c1334b4e6d0E2C";
export const defaultAddress = "0xF78B5c586658E8C3eF81A0359cF4450c18173333";
// export const defaultAddress = "0x7080E2F7eC48835933b34e2d5025c9Daf951Ba7f";
// export const stakingAddress = "0x53465fE2F8b0d309d9eB9B1cf68cD2cA29fa78f8"; //testnet
// export const stakingAddress = "0x50Af7E1267b94c12628e3F54C95d339FF982Bd6F"; //testnet
export const stakingAddress = "0x0b08FFDCe16A57086F511d8652db754E4D65ad5D"; //mainnet

// export const oldStakingAddress = "0xd48754663078fAA22b5Bf40f3a55B4e8E6dbA7b7"; //testnet

export const oldStakingAddress = "0xAfea51307c62AE341238095e5A39e3FC9327A3d4"; //mainnet
