import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { url } from "../../../utils";
import TableCell from "@material-ui/core/TableCell";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { BiDetail } from "react-icons/bi";
import CircularProgress from "@material-ui/core/CircularProgress";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const { tableHead, tableData, tableHeaderColor, setloading, search } = props;
  const classes = useStyles();

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData?.length !== 0 ? (
            tableData.map(
              (
                { _id, addedAt, address, shippingMethod, status, productId },
                key
              ) => (
                <TableRow key={key} className={classes.tableBodyRow}>
                  <TableCell className={classes.tableCell} key={key}>
                    {_id}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {shippingMethod.sprice} $
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {moment(addedAt).format("lll")}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textTransform: "capitalize" }}
                    key={key}
                  >
                    {status}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {address.address}
                  </TableCell>

                  <TableCell className={classes.tableCell} key={key}>
                    <Link to={`/admin/view/${_id}/${productId}`}>
                      <IconButton>
                        <BiDetail />
                      </IconButton>
                    </Link>
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <TableRow className={classes.tableBodyRow}>
              {!search ? (
                <TableCell
                  colSpan="8"
                  align="center"
                  className={classes.tableCell}
                >
                  No product redeemed yet.
                  <br />
                  <CircularProgress style={{ color: "#9931B1" }} />
                </TableCell>
              ) : (
                <TableCell
                  colSpan="8"
                  align="center"
                  className={classes.tableCell}
                >
                  No redeemed product found with this tracking ID "{search}".
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
