import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { url } from "../../../utils";
import Loading from "../loading";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import Table from "../../components/Table/Table4.js";
import { Box, Button, Divider } from "@material-ui/core";
import axios from "axios";
import CustomInput from "../../../user-dashboard/components/CustomInput/CustomInput.js";
import { Search } from "@material-ui/icons";
const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function GoodsServices() {
  const classes = useStyles();

  const [loading, setloading] = useState(false);
  const [search, setsearch] = useState("");
  const [request, setrequest] = useState([]);

  const init = async () => {
    const res = await axios.get(`${url}/goodsservices`);

    setrequest([...res.data.data]);
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Loading open={loading} />
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>
            Goods and Services Requests
          </h4>
        </CardHeader>

        <CardBody>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <div className={classes.searchWrapper}>
              <CustomInput
                value={search}
                onChange={(e) => setsearch(e.target.value)}
                formControlProps={{
                  className: +classes.search,
                }}
                inputProps={{
                  placeholder: "User Name or Number",
                  inputProps: {
                    "aria-label": "Search",
                  },
                }}
              />
              <Button color="white" aria-label="edit" justIcon round>
                <Search />
              </Button>
            </div>
          </Box>
          <Table
            setloading={setloading}
            tableHeaderColor="warning"
            tableHead={[
              "Name",
              "Email",
              "Phone Number",
              "CreatedAt",
              "Industry",
              "Country",
              "City",
            ]}
            init={init}
            tableData={request.filter(
              (v) =>
                v.name.toLowerCase().includes(search.toLowerCase()) ||
                v.phoneNum.toLowerCase().includes(search.toLowerCase())
            )}
            search={search}
          />
        </CardBody>
      </Card>
    </>
  );
}
