/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import EditIcon from "@material-ui/icons/Edit";
import BlurCircularIcon from "@material-ui/icons/BlurCircular";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
// core components/views for Admin layout
import DashboardPage from "./user-dashboard/views/Dashboard/Dashboard.js";
import CoinsAdded from "./user-dashboard/views/coins-added/CoinsAdded.js";
import CoinsVoted from "./user-dashboard/views/coins-voted/CoinsVoted.js";
import Products from "./user-dashboard/views/Products/Products";
import ShippingDetails from "./user-dashboard/views/shipping-details/ShippingDetails";
import Referal from "./user-dashboard/views/referal/referal.js";
import NotificationsPage from "./user-dashboard/views/Notifications/Notifications.js";
import { GiTwoCoins } from "react-icons/gi";
import { GiVote } from "react-icons/gi";
import { MdRedeem } from "react-icons/md";
import { FaShippingFast } from "react-icons/fa";
import { AiOutlineShareAlt } from "react-icons/ai";
// core components/views for RTL layout
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/user",
  },
  {
    path: "/coins-added",
    name: "Coins Added",
    rtlName: "ملف تعريفي للمستخدم",
    icon: GiTwoCoins,
    component: CoinsAdded,
    layout: "/user",
  },
  {
    path: "/coins-voted",
    name: "Coins Voted",
    rtlName: "قائمة الجدول",
    icon: GiVote,
    component: CoinsVoted,
    layout: "/user",
  },
  {
    path: "/products",
    name: "Products Redeemed",
    rtlName: "طباعة",
    icon: MdRedeem,
    component: Products,
    layout: "/user",
  },
  {
    path: "/shipping-details",
    name: "Shipping Details",
    rtlName: "الرموز",
    icon: FaShippingFast,
    component: ShippingDetails,
    layout: "/user",
  },
  {
    path: "/referal-link",
    name: "Referal Link",
    rtlName: "خرائط",
    icon: AiOutlineShareAlt,
    component: Referal,
    layout: "/user",
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/user",
  },
];

export default dashboardRoutes;
