import * as React from 'react';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Box, Button } from '@material-ui/core';
import axios from 'axios'
import { url } from '../../../utils';
import { toast } from 'react-toastify';
export default function AddApiCoin({ addopen, setAddOpen, apicoin, setloading, init }) {
  var [file, setfile] = React.useState("");
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    setAddOpen(false);
  };



  const addCoin = async (name, address, symbol, decimals) => {
    setloading(true);
    setAddOpen(false)
    const dataF = new FormData();
    dataF.append("file", file);
    dataF.append("tokenAddressname", name);
    dataF.append("tokenAddress", address);
    dataF.append("tokenSymbol", symbol);
    dataF.append("tokenDecimal", decimals);
    const { data } = await axios.post(`${url}/admin/suggested-coin`, dataF);

    if (data.status) {
      toast.success(data.message);
      setloading(false);
      init();
      handleClose();
    } else {
      setloading(false);
      toast.error(data.message);
    }
  };
  return (
    <div>
      <Dialog open={addopen} onClose={handleClose} >
        <Grid item xs={12} md={12} align="right" mt={2} >
          <Box p={3}>
            <Box mb={0.5} mt={1} align="center" p={1}>
              <input
                style={{
                  background: "#FFFFFF",
                  border: "1px solid #DD1DE1",
                  fontSize: "18px",
                  height: "40px",
                  outline: "none",
                  borderRadius: "5px",
                  width: "100%",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
                placeholder="Contract Address"
                value={apicoin.address}
              // onChange={(e) => settokenAddress1(e.target.value)}
              />
            </Box>
            <Box mb={0.5} align="center" p={1}>
              <input
                style={{
                  background: "#FFFFFF",
                  border: "1px solid #2329D6",
                  fontSize: "18px",
                  height: "40px",
                  width: "100%",
                  outline: "none",
                  borderRadius: "5px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
                placeholder="Name"
                value={apicoin.name}
              // onChange={(e) => setTokenAddressName1(e.target.value)}
              />
            </Box>
            <Box mb={0.5} align="center" p={1}>
              <input
                style={{
                  background: "#FFFFFF",
                  border: "1px solid #DD1DE1",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  fontSize: "18px",
                  height: "40px",
                  outline: "none",
                  width: "100%",
                  borderRadius: "5px",
                }}
                placeholder="Symbol"
                value={apicoin.symbol}
              // onChange={(e) => settokenSymbol1(e.target.value)}
              />
            </Box>
            <Box mb={0.5} align="center" p={1}>
              <input
                style={{
                  background: "#FFFFFF",
                  border: "1px solid #2329D6",
                  fontSize: "18px",
                  height: "40px",
                  outline: "none",
                  width: "100%",
                  borderRadius: "5px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
                placeholder="Decimal"
                value={apicoin.decimals}
              // onChange={(e) => settokenDecimal1(e.target.value)}
              />
            </Box>
            <Box>
              <input
                style={{
                  background: "#FFFFFF",
                  border: "2px solid #DD1DE1",
                  height: "40px",
                  outline: "none",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  padding: "5px",
                }}
                type="file"
                onChange={(e) => setfile(e.target.files[0])}
              // onChange={(e) => setfile(e.target.files[0])}
              />
            </Box>


            <Box align="center" mt={1}>
              <Button
                onClick={
                  () =>
                    addCoin(
                      apicoin?.name,
                      apicoin?.address,
                      apicoin?.symbol,
                      apicoin?.decimals
                    )
                }
                style={{
                  backgroundColor: "#69FFDB",
                  borderRadius: "5px",
                  height: "2rem",
                }}
                width={{ xs: "100%", md: "40%" }}
              >
                <Box fontWeight={700} fontSize={{ xs: "8px", md: "14px" }}>
                  Add Coin
                </Box>
              </Button>
            </Box>
          </Box>
        </Grid>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}