import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { url } from "../../../utils";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import { FcOk, FcDisclaimer } from "react-icons/fc";
import { RiDeleteBin2Fill } from "react-icons/ri";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const { tableHead, tableData, tableHeaderColor, search } = props;
  const classes = useStyles();

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData?.length !== 0 ? (
            tableData.map(
              (
                {
                  name,
                  email,
                  phoneNum,
                  addedAt,
                  industryName,
                  countryName,
                  cityName,
                },
                key
              ) => (
                <TableRow key={key} className={classes.tableBodyRow}>
                  <TableCell className={classes.tableCell} key={key}>
                    {name}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {email}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {phoneNum}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {moment(addedAt).format("lll")}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {industryName}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {countryName}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {cityName}
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <TableRow className={classes.tableBodyRow}>
              <TableCell
                colSpan="8"
                align="center"
                className={classes.tableCell}
              >
                No Request Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
