import React, { useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Typography, Box, Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import Button from "../../components/CustomButtons/Button.js";
import { AppContext } from "../../../utils.js";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
    paddingTop: "2rem",
  },
  paper: {
    background: "rgba(255,255,255, 1)",
    border: "3px solid #000000",
    boxSizing: "border-box",
    borderRadius: "20px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    marginTop: "auto",
    [theme.breakpoints.down("md")]: {
      marginTop: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "auto",
    },
  },
  connectToWallet: {
    color: "White",
    fontWeight: "bolder",
    marginTop: ".5rem",
    [theme.breakpoints.down("md")]: {
      marginTop: ".3rem",
    },
  },
  closeIconbox: {
    width: "200px",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    color: "#27CFA7",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "180px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "160px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "150px",
    },
  },
  Boxone: {
    backgroundColor: "white",
    width: "100%",
    height: "43px",
    borderRadius: "20px",
    padding: ".2rem",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: ".5rem",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      height: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "38px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "35px",
    },
  },
  wallet: {
    padding: ".6rem",
    fontSize: "1.1rem",
    color: "#7F147F",
    fontWeight: "bolder",
  },
  wimg: {
    padding: ".6rem",
    width: "2rem",
    height: "2rem",
    [theme.breakpoints.down("md")]: {
      width: "1.8rem",
      height: "1.8rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "1.6rem",
      height: "1.6rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "1.5rem",
      height: "1.5rem",
    },
  },
  inputField: {
    width: "500px",
    height: "40px",
    background: "#FFFFFF",
    border: "1px solid #000000",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  btn: {
    padding: ".1rem 2em",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "15px",
    lineHeight: "160%",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    backgroundColor: theme.palette.common.btnbg,
    borderRadius: "1rem",
    marginLeft: ".5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: ".9rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
  },
  Invite: {
    fontSize: "1rem",
    [theme.breakpoints.down("md")]: {
      fontSize: ".9rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
  },
}));

export default function SocialModal({ open, setOpen, Open }) {
  const { account } = useContext(AppContext);

  const [html, sethtml] = React.useState("");
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    if (account) {
      var emb = `<script type='text/javascript' charset='utf-8'>
        <iframe src='${window.location.origin}/?ref=${account}' width='500px' height='500px'/>
      </script>`;
      // var script = document.createElement("script");
      // var iframe = document.createElement("iframe");

      // script.setAttribute("type", "text/javascript");
      // script.setAttribute("charset", "utf-8");
      // script.appendChild(iframe);

      // iframe.src = window.location.origin + "/?ref=" + account;
      // iframe.width = "500px";
      // iframe.height = "500px";

      sethtml(emb);
    }
  }, [account]);
  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Referal Link</h4>
          <p className={classes.cardCategoryWhite}>
            Share! to invite your friends to the party! When they buy a ticket,
            you get 20%. Loud it!!!
          </p>
        </CardHeader>

        <CardBody>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography className={classes.connectToWallet}>
              Invite by Sharing
            </Typography>
          </Box>
          <Typography className={classes.Invite}>
            Share! to invite your friends to the party! When they buy a ticket,
            you get 20%. Loud it!!!
          </Typography>

          <hr style={{ border: "1px solid #000000" }} />
          <Box>
            <Grid container spacing={3}>
              <Grid item lg={2} sm={3}>
                <FacebookShareButton
                  url={`${window.location.origin}/?ref=${account}`}
                  quote={
                    "Come join me at the Biggest Virtual Crypto Party Event with 80% buyback price from the community, invitation bonus in BNB, BUSD & BSC TOKENS, fun and so much more!"
                  }
                  hashtag="#livecryptoparty"
                >
                  <img
                    className={classes.wimg}
                    src="/assets/facebook.png"
                    alt=""
                  />
                </FacebookShareButton>
                <Typography className={classes.Invite}>Facebook</Typography>
              </Grid>
              <Grid item lg={2} sm={3}>
                <TwitterShareButton
                  url={`${window.location.origin}/?ref=${account}`}
                  title={
                    "Come join me at the Biggest Virtual Crypto Party Event with 80% buyback price from the community, invitation bonus in BNB, BUSD & BSC TOKENS, fun and so much more!"
                  }
                  hashtag={["#livecryptoparty"]}
                >
                  <img
                    className={classes.wimg}
                    src="/assets/twitter.png"
                    alt=""
                  />
                </TwitterShareButton>
                <Typography className={classes.Invite}>Twitter</Typography>
              </Grid>
              <Grid item lg={2} sm={3}>
                <WhatsappShareButton
                  url={`${window.location.origin}/?ref=${account}`.toString()}
                  title={
                    "Come join me at the Biggest Virtual Crypto Party Event with 80% buyback price from the community, invitation bonus in BNB, BUSD & BSC TOKENS, fun and so much more!"
                  }
                >
                  <img
                    className={classes.wimg}
                    src="/assets/whatsapp.png"
                    alt=""
                  />
                </WhatsappShareButton>
                <Typography className={classes.Invite}>WhatsApp</Typography>
              </Grid>
              <Grid item lg={2} sm={3}>
                <EmailShareButton
                  url={`${window.location.origin}/?ref=${account}`}
                  subject={"Invite To Live Crypto Party"}
                  body={
                    "Come join me at the Biggest Virtual Crypto Party Event with 80% buyback price from the community, invitation bonus in BNB, BUSD & BSC TOKENS, fun and so much more!"
                  }
                >
                  <img className={classes.wimg} src="/assets/mail.png" alt="" />
                </EmailShareButton>
                <Typography className={classes.Invite}>Email</Typography>
              </Grid>
              <Grid item lg={6} sm={3}>
                <CopyToClipboard
                  text={account && html}
                  onCopy={() => {
                    !account
                      ? toast.error("Connect Wallet!")
                      : toast.success("Link Copied!");
                  }}
                >
                  <Typography style={{ cursor: "pointer" }}>
                    <img
                      className={classes.wimg}
                      src="/assets/code.png"
                      alt=""
                    />
                  </Typography>
                </CopyToClipboard>
                <Typography className={classes.Invite}>Embed HTML</Typography>
              </Grid>
            </Grid>

            <Grid>
              <Typography className={classes.Invite}>
                Copy referral link
              </Typography>

              <Grid container alignItems="center">
                <Typography>
                  <Box
                    className={classes.inputField}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <input
                      readOnly
                      value={
                        account
                          ? window.location.origin + "/?ref=" + account
                          : "Connect your Wallet"
                      }
                      style={{
                        outline: "none",
                        border: "none",
                        height: "90%",
                        width: account ? "99%" : "70%",
                      }}
                    />
                    {!account && (
                      <Button
                        style={{
                          backgroundColor: "#EBECEC",
                          color: "#212353",
                          fontSize: "15px",
                          fontWeight: 700,
                          outline: "none",
                          border: "none",
                          borderRadius: "10px",
                          padding: "4px 16px",
                          marginBottom: "10px",
                        }}
                        onClick={() => {
                          Open(true);
                          handleClose();
                        }}
                      >
                        <PowerSettingsNewIcon
                          color="#212353"
                          style={{ fontSize: "20px", marginRight: "8px" }}
                        />
                        Connect
                      </Button>
                    )}
                  </Box>
                </Typography>
                <CopyToClipboard
                  text={account && window.location.origin + "/?ref=" + account}
                  onCopy={() => {
                    !account
                      ? toast.error("Connect Wallet!")
                      : toast.success("Link Copied!");
                  }}
                >
                  <Button
                    color="success"
                    round
                    style={{ marginBottom: "10px" }}
                  >
                    Copy
                  </Button>
                </CopyToClipboard>
              </Grid>
              <Grid item lg={12} sm={3} style={{ textAlign: "center" }}>
                <Typography className={classes.Invite}>
                  <span style={{ fontSize: "1.5rem" }}>Tips: </span> Paste your
                  referral link anywhere
                </Typography>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <img
                className={classes.wimg}
                src="/assets/instagram.png"
                alt=""
              />
              <img className={classes.wimg} src="/assets/youtube.png" alt="" />
              <img className={classes.wimg} src="/assets/Tiktok.png" alt="" />
              <img
                className={classes.wimg}
                src="/assets/messenger.png"
                alt=""
              />
            </Grid>
          </Box>
        </CardBody>
      </Card>
    </div>
  );
}
