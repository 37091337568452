import React, { useState, useEffect, useContext, Suspense } from "react";
import { Route } from "react-router-dom";
import "./App.css";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import axios from "axios";
import { url } from "./ConectivityAssests/hooks/Hooks";
import { AppContext } from "./utils";
import Loading from "./loading";

const Footer = React.lazy(() => import("./components/Footer"));
const Features = React.lazy(() => import("./components/Features"));
const Roadmap = React.lazy(() => import("./components/Roadmap"));
const TokenDistribution = React.lazy(() =>
  import("./components/TokenDistribution")
);
const ReferalModal = React.lazy(() =>
  import("./components/Forms/ReferalModal")
);
const LogoutModal = React.lazy(() =>
  import("./ConectivityAssests/logOutModel/logoutModal")
);
const LCPToken = React.lazy(() => import("./components/Forms/LCPToken"));
const NetworkChange = React.lazy(() =>
  import("./ConectivityAssests/modelBox/networkSwitch")
);
const WhyUs = React.lazy(() => import("./components/WhyUs"));
const HowToBuy = React.lazy(() => import("./components/HowToBuy"));
const SpinGame = React.lazy(() => import("./components/SpinGame"));
const Participants = React.lazy(() => import("./components/Participants"));
const Dashboard = React.lazy(() =>
  import("./components/referralChart/Dashboard")
);
const Rank = React.lazy(() => import("./components/referralChart/Rank"));
const Main2 = React.lazy(() => import("./components/referralChart/Main"));
const WaitingList = React.lazy(() => import("./components/Forms/WaitingList"));
const FAQ = React.lazy(() => import("./components/FAQ"));
const Disclaimer = React.lazy(() => import("./components/Disclaimer"));
const Services = React.lazy(() => import("./components/Services"));
const CommingSoon = React.lazy(() => import("./components/CommingSoon"));
const LCPSpinGame = React.lazy(() => import("./components/LCPSpinGame"));
const BackToTop = React.lazy(() => import("./components/ScrollUp"));
const AddCoinModal = React.lazy(() =>
  import("./components/Forms/AddCoinModal")
);
const BuyBackModal = React.lazy(() =>
  import("./components/Forms/BuyBackModal")
);
const TicketModal = React.lazy(() => import("./components/Forms/TicketModal"));
const ShippingModal = React.lazy(() =>
  import("./components/Forms/ShippingModal")
);
const LCPFrame = React.lazy(() => import("./components/LCPFrame"));
const StakingMain = React.lazy(() =>
  import("./components/Staking/StakingMain")
);
const LCPCalculator = React.lazy(() =>
  import("./components/Staking/LCPCalculator")
);
const Statistics = React.lazy(() => import("./components/Staking/Statistics"));
const GiveAway = React.lazy(() => import("./components/Giveaway"));
const GiveawayReferalModal = React.lazy(() =>
  import("./components/Forms/GiveawayReferalModal")
);
const Header = React.lazy(() => import("./components/Header"));
const Main = React.lazy(() => import("./components/Main"));
const About = React.lazy(() => import("./components/About"));
const HowItWork = React.lazy(() => import("./components/HowItWork"));
const Party = React.lazy(() => import("./components/Party"));
const Redeem = React.lazy(() => import("./components/Redeem"));

function App() {
  const [referralModal, setReferralModal] = useState();
  const [waitingListModal, setWaitingListModal] = useState(false);
  const [buyBackModal, setBuyBackModal] = useState(false);
  const [ticketModal, setTicketModal] = useState(false);
  const [shippingModalOpen, setShippingModalOpen] = useState(false);
  const [addCoinModal, setAddCoinModal] = useState(false);
  const [lcpModal, setLcpModal] = useState();
  const [logoutModal, setLogoutModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [switchNetwork, setSwitchNetwork] = useState(false);
  const { account } = useContext(AppContext);
  const [giveawayModal, setgiveawayModal] = useState(false);
  const [suggestedCoins, setsuggestedCoins] = useState([]);
  const [data, setdata] = useState({});

  useEffect(() => {
    if (window.location.href.includes("?ref=")) {
      let getAddress = window.location.href.split("?ref=")[1];
      let final = getAddress.slice(0, 42);
      localStorage.setItem("LCP_REFERRAL", final);
    }
    const init = async () => {
      try {
        const { data } = await axios.get(`${url}/admin/get`);
        setdata(data.data[0]);
        const res = await axios.get(`${url}/admin/suggectedCoins`);
        setsuggestedCoins([...res.data.data]);
        if (account) {
          await axios.post(`${url}/user/create-user`, { address: account });
        }
      } catch (error) {
        console.log(error);
      }
    };
    init();
  }, [account]);

  return (
    <div id="backToTop">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<Loading loading={true} />}>
          <NetworkChange open={switchNetwork} setOpen={setSwitchNetwork} />
        </Suspense>
        <Suspense fallback={<Loading loading={true} />}>
          <WaitingList setOpen={setWaitingListModal} open={waitingListModal} />
        </Suspense>
        <Suspense fallback={<Loading loading={true} />}>
          <AddCoinModal setOpen={setAddCoinModal} open={addCoinModal} />
        </Suspense>
        <Suspense fallback={<Loading loading={true} />}>
          <BuyBackModal
            setLoginModal={setLoginModal}
            setOpen={setBuyBackModal}
            open={buyBackModal}
          />
        </Suspense>
        <Suspense fallback={<Loading loading={true} />}>
          <TicketModal
            setLoginModal={setLoginModal}
            setOpen={setTicketModal}
            open={ticketModal}
          />
        </Suspense>
        <Suspense fallback={<Loading loading={true} />}>
          <ShippingModal
            setOpen={setShippingModalOpen}
            open={shippingModalOpen}
          />
        </Suspense>
        <Suspense fallback={<Loading loading={true} />}>
          <LCPToken open={lcpModal} setOpen={setLcpModal} />
        </Suspense>
        <Suspense fallback={<Loading loading={true} />}>
          <ReferalModal setOpen={setReferralModal} open={referralModal} />
        </Suspense>
        <Suspense fallback={<Loading loading={true} />}>
          <GiveawayReferalModal
            setOpen={setgiveawayModal}
            open={giveawayModal}
          />
        </Suspense>
        <Suspense fallback={<Loading loading={true} />}>
          <LogoutModal setOpen={setLogoutModal} open={logoutModal} />
        </Suspense>
        <Suspense fallback={<Loading loading={true} />}>
          <Header
            setLoginModal={setLoginModal}
            setLogoutModal={setLogoutModal}
            data={data}
          />
        </Suspense>
        {/* <Route exact path="/presale">
          <>
            <Main open={lcpModal} setOpen={setLcpModal} />
            <WhyUs setOpen={setReferralModal} />
            <HowToBuy />
            <SpinGame />
            <Participants />
            <Redeem data={data} setOpen={setWaitingListModal} />
            
            <Roadmap />
            <TokenDistribution />
          </>
        </Route>
         */}
        <Route path="/" exact>
          <>
            <Suspense fallback={<Loading loading={true} />}>
              <Main open={lcpModal} setOpen={setLcpModal} />
              <About setOpen={setReferralModal} />
              <HowItWork />
              <Party setOpen={setAddCoinModal} />
              <Redeem data={data} setOpen={setWaitingListModal} />
              <Roadmap />
            </Suspense>
          </>
        </Route>
        <Route exact path="/referralchart">
          <>
            <Suspense fallback={<Loading loading={true} />}>
              <Main2 open={lcpModal} setOpen={setLcpModal} />
              <Dashboard setOpen={setReferralModal} />
              <Rank setOpen={setReferralModal} />
            </Suspense>
          </>
        </Route>
        <Route exact path="/lcpframe">
          <Suspense fallback={<Loading loading={true} />}>
            <LCPFrame />
          </Suspense>
        </Route>

        <Route exact path="/faq">
          <Suspense fallback={<Loading loading={true} />}>
            <FAQ />
          </Suspense>
        </Route>
        <Route exact path="/disclaimer">
          <Suspense fallback={<Loading loading={true} />}>
            <Disclaimer />
          </Suspense>
        </Route>
        <Route exact path="/services">
          <Suspense fallback={<Loading loading={true} />}>
            <Services />
          </Suspense>
        </Route>
        <Route exact path="/comingsoon">
          <Suspense fallback={<Loading loading={true} />}>
            <CommingSoon />
          </Suspense>
        </Route>
        <Route exact path="/party">
          <Suspense fallback={<Loading loading={true} />}>
            <Party />
          </Suspense>
        </Route>
        <Route exact path="/lcpspin">
          <Suspense fallback={<Loading loading={true} />}>
            <LCPSpinGame />
          </Suspense>
        </Route>
        <Route exact path="/giveaway">
          <Suspense fallback={<Loading loading={true} />}>
            <GiveAway setBuyModal={setLcpModal} setOpen={setgiveawayModal} />
          </Suspense>
        </Route>
        <Route path="/staking">
          <Suspense fallback={<Loading loading={true} />}>
            <StakingMain />
            <LCPCalculator />
            <Statistics />
          </Suspense>
        </Route>
        <Suspense fallback={<Loading loading={true} />}>
          <Features />
        </Suspense>
        <Suspense fallback={<Loading loading={true} />}>
          <Footer />
        </Suspense>
        <Suspense fallback={<Loading loading={true} />}>
          <BackToTop />
        </Suspense>
      </ThemeProvider>
    </div>
  );
}

export default App;
