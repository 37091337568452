import React, { useState, useEffect } from "react";
// @material-ui/core ../../components
import { makeStyles } from "@material-ui/core/styles";
// core ../../components
import Quote from "../../components/Typography/Quote.js";
import Muted from "../../components/Typography/Muted.js";
import Primary from "../../components/Typography/Primary.js";
import Info from "../../components/Typography/Info.js";
import { url } from "../../../utils";
import Loading from "../loading";

import Success from "../../components/Typography/Success.js";
import Warning from "../../components/Typography/Warning.js";
import Danger from "../../components/Typography/Danger.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import Table from "../../components/Table/Table1.js";
import { Box, Button, Divider, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import axios from "axios";

import CustomInput from "../../../user-dashboard/components/CustomInput/CustomInput.js";
import { Search } from "@material-ui/icons";
const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function Products() {
  const classes = useStyles();
  const [loading, setloading] = useState(false);
  const [orders, setorders] = useState([]);

  const [search, setsearch] = useState("");

  useEffect(() => {
    const init = async () => {
      const res = await axios.get(`${url}/order/get`);
      setorders([...res.data.data]);
    };
    init();
  }, []);
  return (
    <>
      <Loading open={loading} />
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Products Redeemed</h4>
          <p className={classes.cardCategoryWhite}>Manage redeemed products</p>
        </CardHeader>

        <CardBody>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Box component="h3" textAlign="left" fontSize={20}>
              Products Redeemed
            </Box>
            <div className={classes.searchWrapper}>
              <CustomInput
                value={search}
                onChange={(e) => setsearch(e.target.value)}
                formControlProps={{
                  className: +classes.search,
                }}
                inputProps={{
                  placeholder: "Tracking ID",
                  inputProps: {
                    "aria-label": "Search",
                  },
                }}
              />
              <Button color="white" aria-label="edit" justIcon round>
                <Search />
              </Button>
            </div>
          </Box>
          <Table
            setloading={setloading}
            tableHeaderColor="warning"
            tableHead={[
              "Tracking ID",
              "Shipping Fee",
              "Order Date",
              "Status",
              "Shipping Address",
              "View Details",
            ]}
            tableData={orders.filter((v) =>
              v._id.toLowerCase().includes(search.toLowerCase())
            )}
            search={search}
          />
        </CardBody>
      </Card>
    </>
  );
}
