import React, { useState, useEffect } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Table from "../../components/Table/Table.js";
import Tasks from "../../components/Tasks/Tasks.js";
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";
// import Danger from "components/Typography/Danger.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { url } from "../../../utils";
import { useCrypopartyContract } from "../../../ConectivityAssests/hooks/Hooks.js";

import { bugs, website, server } from "../../variables/general.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "../../variables/charts.js";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import axios from "axios";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [coin, setcoin] = useState(0);
  const [scoin, setscoin] = useState(0);
  const [user, setuser] = useState(0);
  const [refer, setrefer] = useState(0);
  const [order, setorder] = useState(0);
  const [product, setproduct] = useState(0);
  const [Lcp, setLcp] = useState(0);
  const cryptopartyContract = useCrypopartyContract();
  const [sold, setsold] = useState(0);
  const [total, settotal] = useState(0);
  useEffect(() => {
    const init = async () => {
      try {
        const total = (await cryptopartyContract.ClaimableTickets()).toNumber();
        const solds = (
          await cryptopartyContract.TickesBoughtGlobal()
        ).toNumber();
        settotal(total);
        setsold(solds);
      } catch (error) {
        console.log(error);
      }
    };
    if (cryptopartyContract) {
      init();
    }
  }, [cryptopartyContract]);
  useEffect(() => {
    const init = async () => {
      const { data } = await axios.get(`${url}/product/length`);
      let { data: data1 } = data;
      setcoin(data1.cl?.length);
      setscoin(data1.sl);
      setrefer(data1.rl);
      setuser(data1.ul);
      setproduct(data1.pl);
      setorder(data1.order?.length);
      var lcp = 0;
      if (data1?.order?.length !== 0) {
        for (let i = 0; i < data1?.order?.length; i++) {
          lcp += +data1?.order[i].LCPTokens;
        }
        setLcp(lcp);
      }
    };
    init();
  }, []);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <i className="fad fa-users"></i>
              </CardIcon>
              <p className={classes.cardCategory}>Total Users</p>
              <h3 className={classes.cardTitle}>
                <small>{user}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                Users that are participating in party
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <i className="fad fa-bags-shopping"></i>
              </CardIcon>
              <p className={classes.cardCategory}>Orders</p>
              <h3 className={classes.cardTitle}>
                {" "}
                <small>{order}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                just now
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <i className="fad fa-box"></i>
              </CardIcon>
              <p className={classes.cardCategory}>Total Products</p>
              <h3 className={classes.cardTitle}>
                {" "}
                <small>{product}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                All Types
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <i className="fad fa-coin"></i>
              </CardIcon>
              <p className={classes.cardCategory}>Suggested Coins</p>
              <h3 className={classes.cardTitle}>
                {" "}
                <small>{scoin}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="danger">
                <i className="fad fa-users-crown"></i>
              </CardIcon>
              <p className={classes.cardCategory}>Referred Users</p>
              <h3 className={classes.cardTitle}>
                {" "}
                <small>{refer}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="primary">
                <i class="fal fa-coin"></i>
              </CardIcon>
              <p className={classes.cardCategory}>Users coin</p>
              <h3 className={classes.cardTitle}>
                {" "}
                <small>{coin}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="success">
                <i class="fad fa-ticket-alt"></i>
              </CardIcon>
              <p className={classes.cardCategory}>Sold Tickets</p>
              <h3 className={classes.cardTitle}>
                {" "}
                <small>{sold + "/" + total}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <i class="fal fa-coin"></i>
              </CardIcon>
              <p className={classes.cardCategory}>LCP Tokens Redeemed</p>
              <h3 className={classes.cardTitle}>
                {" "}
                <small>{Lcp}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
