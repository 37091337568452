import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  useTheme,
  Container,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

import AddIcon from "@material-ui/icons/Add";

import axios from "axios";
import Slide from "@material-ui/core/Slide";
import Loading from "../loading";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { toast } from "react-toastify";
import { url, getTokenContract, AppContext } from "../../../utils";
import moment from "moment";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledModal = withStyles((theme) => ({
  root: {
    "& .MuiDialog-paperWidthLg": {
      width: "1000px",
    },
    "& .MuiDialog-paperScrollPaper": {
      width: "1000px",
      // height: "712px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "30px !important",
    },
    "& .MuiDialog-paperWidthSm": {
      // width: "1000px",
      // height: "972px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      // borderRadius: "30px !important",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
      // padding: "5px",
    },
    "& .dialoge__content__section": {
      // width: "1000px",
      padding: "0px !important",
      // backgroundColor: "rgba(117, 52, 226, 0.7)",
      // borderRadius: "23px",
      // width: "2053px",
      // width: "100%",
      // height: "100%",
    },
  },
}))(Dialog);
const useStyles = makeStyles((theme) => ({
  deletebtn: {
    background: "#FF0000",
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#fff",
    padding: ".2rem 1rem",
    "&:hover": {
      background: "#FF0000b1",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".7rem",
    },
  },
  selectOptions: {
    width: "100%",
    background: "#FFFFFF",
    fontSize: "bolder",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "0.6rem 0.2rem",
  },
  tdata: {
    width: "25%",
    height: "auto",
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "25px",
    lineHeight: "130%",
    padding: "2rem 1rem",
    color: "#000000",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".7rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".7rem",
    },
  },
}));
export default function SuggestedCoins({ search, setstatus, coinsAdded }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { signer } = useContext(AppContext);
  const [addcoinModal, setaddcoinModal] = useState(false);
  const [tokenAddressname, setTokenAddressName] = useState("");
  const [tokenSymbol, settokenSymbol] = useState("");
  const [tokenDecimal, settokenDecimal] = useState("");
  const [tokenAddress, settokenAddress] = useState("");
  const [tokenAddressname1, setTokenAddressName1] = useState("");
  const [tokenSymbol1, settokenSymbol1] = useState("");
  const [tokenDecimal1, settokenDecimal1] = useState("");
  const [tokenAddress1, settokenAddress1] = useState("");
  const [coinsSdata, setSCoins] = useState([]);

  var [file, setfile] = useState("");
  const { spacing } = useTheme();
  const [loading, setloading] = useState(false);
  const [id, setid] = useState("");
  var smoothScroll = function () {
    var scrollContainer = document.getElementById("scrolling");
    scrollContainer = scrollContainer.parentNode;
    scrollContainer.scrollTop += 40;
  };
  const addCoin = async () => {};

  const addCoinHandler = async (id) => {
    try {
      setloading(true);
      setstatus(false);
      const { data } = await axios.post(`${url}/coin/add-suggested`, {
        id: id,
      });

      if (data.status) {
        toast.success(data.message);
        setloading(false);
        setaddcoinModal(false);
        setstatus(false);
      } else {
        toast.error(data.message);
        setloading(false);
        setaddcoinModal(false);
      }
    } catch (error) {
      setloading(false);
      console.error("addToken", error);
    }
  };

  const handleSearch1 = (e) => {
    const value = e.target.value;
    if (value) {
      const find = coinsAdded.filter(
        (v) =>
          v.tokenName.toLowerCase().includes(value.toLowerCase()) ||
          v.tokenAddress.toLowerCase().includes(value.toLowerCase())
      );

      setSCoins(find);
    } else {
      setSCoins(coinsAdded);
    }
  };
  const handleTokenAddress = async (e) => {
    const value = e.target.value.trim();
    settokenAddress(value);
    try {
      if (value) {
        setloading(true);
        const tokenContract = getTokenContract(value, signer);

        const name = await tokenContract.name();
        const symbol = await tokenContract.symbol();
        const decimal = await tokenContract.decimals();
        setTokenAddressName(name);
        settokenSymbol(symbol);
        settokenDecimal(decimal);
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      console.error("errrrro=>", error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const detailsHandler = async () => {
    if (!tokenAddress) {
      toast.error("please enter token address first.");
    } else if (!tokenAddressname) {
      toast.error("Token Address is invalid");
    } else if (!file) {
      toast.error("Please select token logo.");
    } else {
      setloading(true);
      setstatus(false);
      const dataF = new FormData();
      dataF.append("file", file);
      dataF.append("_id", id);
      dataF.append("tokenAddressname", tokenAddressname);
      dataF.append("tokenAddress", tokenAddress);
      dataF.append("tokenSymbol", tokenSymbol);
      dataF.append("tokenDecimal", tokenDecimal);
      const { data } = await axios.post(`${url}/admin/suggested-coin`, dataF);
      if (data.status) {
        toast.success(data.message);
        setstatus(true);
        setloading(false);
        setOpen(false);
      } else {
        setloading(false);
        toast.error(data.message);
      }
    }
  };
  const handleDelete = async (id) => {
    try {
      setloading(true);
      setstatus(false);
      const { data } = await axios.post(`${url}/admin/delete`, {
        id: id,
      });
      toast.success(data.message);
      setstatus(true);
      setloading(false);
    } catch (error) {
      setloading(false);
      toast.error(error.message);
    }
  };
  return (
    <>
      <Box component="h3" p={spacing(0.4)} position="relative">
        <Grid direction="row">
          <table width="100%" style={{ borderSpacing: "0 1rem" }}>
            <tr
              style={{
                width: "100%",
                outline: "3px solid #2329D6",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <td className={classes.tdata} style={{ textAlign: "left" }}>
                Rank
              </td>
              <td className={classes.tdata}>Coin Name</td>
              <td className={classes.tdata}>Votes</td>
              <td className={classes.tdata}>Created At</td>
            </tr>
            {coinsAdded?.length !== 0 ? (
              coinsAdded?.map((item, i) => (
                <tr
                  style={{
                    width: "100%",
                    outline:
                      i % 2 !== 0 ? "3px solid #2329D6" : "3px solid #DD1DE1",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <td className={classes.tdata} style={{ display: "flex" }}>
                    {++i}
                  </td>
                  <td className={classes.tdata}> {item.name}</td>
                  <td className={classes.tdata}> {item.votes}</td>
                  <td className={classes.tdata}>
                    {" "}
                    {moment(item.addedAt).format("lll")}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                {!search ? (
                  <td
                    textAlign="center"
                    colspan="6"
                    className={classes.tdata}
                    style={{
                      border: "3px solid #DD1DE1",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    No coin Added by you yet.
                  </td>
                ) : (
                  <td
                    textAlign="center"
                    colspan="6"
                    className={classes.tdata}
                    style={{
                      border: "3px solid #DD1DE1",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    No coin found with this coin name "{search}".
                  </td>
                )}
              </tr>
            )}
          </table>
        </Grid>
      </Box>
    </>
  );
}
