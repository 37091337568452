import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import styles1 from "../../assets/jss/material-dashboard-react/components/tasksStyle.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { url } from "../../../utils";
import axios from "axios";
import Percentage from "./Percentage";
import BuybackCrypto from "./BuybackCrypto";
import Timer from "./Timer";
import TotalTickets from "./TotalTickets";
import PartyPicture from "./PartyPicture";
import TicketPrice from "./TicketPrice";
import CurrencyAccepting from "./CurrencyWeAreAccepting";
import BuybackLock from "./BuybackLock";
import RedeemLock from "./redeemLock";
import CurrencyAcceptingForShip from "./CurrencyWeAreAcceptingForShip";
import Theme from "./theme.jsx";
import WelcomeMessageLock from "./welcomeMessageLock.jsx";
import TicketLock from "./TicketLock.jsx";
// TicketLock

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles({ ...styles, ...styles1 });

export default function Variables() {
  const classes = useStyles();
  const [data, setdata] = useState({});
  const [status, setstatus] = useState(false);
  useEffect(() => {
    const init = async () => {
      const { data } = await axios.get(`${url}/admin/get`);
      setdata(data.data[0]);
    };
    init();
  }, [status]);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Variables</h4>
              <p className={classes.cardCategoryWhite}>
                Edit administration variables
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow className={classes.tableRow}>
                        <Percentage setstatus={setstatus} data={data} />
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <Theme setstatus={setstatus} data={data} />
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <BuybackCrypto setstatus={setstatus} data={data} />
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <Timer setstatus={setstatus} data={data} />
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <TotalTickets setstatus={setstatus} data={data} />
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <PartyPicture setstatus={setstatus} data={data} />
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <TicketPrice setstatus={setstatus} data={data} />
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <CurrencyAccepting setstatus={setstatus} data={data} />
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <BuybackLock setstatus={setstatus} data={data} />
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <RedeemLock setstatus={setstatus} data={data} />
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <WelcomeMessageLock setstatus={setstatus} data={data} />
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <CurrencyAcceptingForShip
                          setstatus={setstatus}
                          data={data}
                        />
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <TicketLock
                          setstatus={setstatus}
                          data={data}
                        />
                      </TableRow>
                    </TableBody>
                  </Table>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
