/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import EditIcon from "@material-ui/icons/Edit";
import BlurCircularIcon from "@material-ui/icons/BlurCircular";
import { GiTwoCoins } from "react-icons/gi";
import { FaCoins } from "react-icons/fa";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import BallotIcon from "@material-ui/icons/Ballot";
// core components/views for Admin layout
import DashboardPage from "./admin-dashboard/views/Dashboard/Dashboard.js";
import Variables from "./admin-dashboard/views/Variables/Variables.js";
import SuggestedCoins from "./admin-dashboard/views/SuggestedCoins/SuggestedCoins.js";
import CoinsAdded from "./admin-dashboard/views/coins-added/CoinsAdded";
import Products from "./admin-dashboard/views/Products/Products";
import Orders from "./admin-dashboard/views/orders/orders.js";
import NotificationsPage from "./admin-dashboard/views/Notifications/Notifications.js";
import GoodsServices from "./admin-dashboard/views/GoodsServices/GoodsServices.jsx";
// core components/views for RTL layout
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/Variables",
    name: "Variables",
    rtlName: "ملف تعريفي للمستخدم",
    icon: EditIcon,
    component: Variables,
    layout: "/admin",
  },
  {
    path: "/coins-added",
    name: "Coins Added",
    rtlName: "قائمة الجدول",
    icon: GiTwoCoins,
    component: CoinsAdded,
    layout: "/admin",
  },
  {
    path: "/suggested-coins",
    name: "Suggested Coins",
    rtlName: "قائمة الجدول",
    icon: FaCoins,
    component: SuggestedCoins,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Products",
    rtlName: "طباعة",
    icon: MarkunreadMailboxIcon,
    component: Products,
    layout: "/admin",
  },
  {
    path: "/goodsservices",
    name: "Goods & Services",
    rtlName: "طباعة",
    icon: BallotIcon,
    component: GoodsServices,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders",
    rtlName: "الرموز",
    icon: ShoppingBasketIcon,
    component: Orders,
    layout: "/admin",
  },

  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin",
  },
];

export default dashboardRoutes;
