import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  useTheme,
  Container,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  withStyles,
  Avatar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

import AddIcon from "@material-ui/icons/Add";

import axios from "axios";
import Slide from "@material-ui/core/Slide";
import Loading from "../loading";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { toast } from "react-toastify";
import { url, getTokenContract } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  deletebtn: {
    background: "#FF0000",
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#fff",
    padding: ".2rem 1rem",
    "&:hover": {
      background: "#FF0000b1",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".7rem",
    },
  },
  selectOptions: {
    width: "100%",
    background: "#FFFFFF",
    fontSize: "bolder",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "0.6rem 0.2rem",
  },
  purple: {
    color: "#000000",
    backgroundColor: "#69FFDB",
    fontSize: "10px",
    fontWeight: 900,
    padding: "10px",
    border: "2px solid #DD1DE1",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  tdata: {
    width: "25%",
    height: "auto",
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "25px",
    lineHeight: "130%",
    padding: "2rem 1rem",
    color: "#000000",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".7rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".7rem",
    },
  },
}));
export default function CustomCoin({ init, handleClose }) {
  const classes = useStyles();

  const [tokenAddressname1, setTokenAddressName1] = useState("");
  const [tokenSymbol1, settokenSymbol1] = useState("");
  const [tokenDecimal1, settokenDecimal1] = useState("");
  const [tokenAddress1, settokenAddress1] = useState("");

  const { spacing } = useTheme();
  const [loading, setloading] = useState(false);
  const [id, setid] = useState("");
  const [file, setFile] = useState("");
  const addCoin = async () => {
    if (!tokenAddress1) {
      toast.error("please enter token address first.");
    } else if (!tokenAddressname1) {
      toast.error("please enter token name first.");
    } else if (!tokenSymbol1) {
      toast.error("please enter token symbol first.");
    } else if (!tokenDecimal1) {
      toast.error("please enter token decimals first.");
    } else {
      setloading(true);
      const dataF = new FormData();
      dataF.append("file", file);
      dataF.append("tokenAddressname", tokenAddressname1);
      dataF.append("tokenAddress", tokenAddress1);
      dataF.append("tokenSymbol", tokenSymbol1);
      dataF.append("tokenDecimal", tokenDecimal1);
      const { data } = await axios.post(`${url}/admin/suggested-coin`, dataF);
      if (data.status) {
        toast.success(data.message);
        setloading(false);
        init();
        handleClose();
      } else {
        setloading(false);
        toast.error(data.message);
      }
    }
  };
  return (
    <Grid item xs={4} md={4} align="right" mt={2}>
      <Box mb={0.5} mt={1} align="center" p={1}>
        <input
          style={{
            background: "#FFFFFF",
            border: "1px solid #DD1DE1",
            fontSize: "18px",
            height: "40px",
            outline: "none",
            borderRadius: "5px",
            width: "100%",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
          placeholder="Contract Address"
          value={tokenAddress1}
          onChange={(e) => settokenAddress1(e.target.value)}
        />
      </Box>
      <Box mb={0.5} align="center" p={1}>
        <input
          style={{
            background: "#FFFFFF",
            border: "1px solid #2329D6",
            fontSize: "18px",
            height: "40px",
            width: "100%",
            outline: "none",
            borderRadius: "5px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
          placeholder="Name"
          value={tokenAddressname1}
          onChange={(e) => setTokenAddressName1(e.target.value)}
        />
      </Box>
      <Box mb={0.5} align="center" p={1}>
        <input
          style={{
            background: "#FFFFFF",
            border: "1px solid #DD1DE1",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            fontSize: "18px",
            height: "40px",
            outline: "none",
            width: "100%",
            borderRadius: "5px",
          }}
          placeholder="Symbol"
          value={tokenSymbol1}
          onChange={(e) => settokenSymbol1(e.target.value)}
        />
      </Box>
      <Box mb={0.5} align="center" p={1}>
        <input
          style={{
            background: "#FFFFFF",
            border: "1px solid #2329D6",
            fontSize: "18px",
            height: "40px",
            outline: "none",
            width: "100%",
            borderRadius: "5px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
          placeholder="Decimal"
          value={tokenDecimal1}
          onChange={(e) => settokenDecimal1(e.target.value)}
        />
      </Box>
      <Box mb={0.5} align="center" p={1}>
        <input
          style={{
            background: "#FFFFFF",
            border: "1px solid #2329D6",
            fontSize: "18px",
            height: "40px",
            outline: "none",
            width: "100%",
            borderRadius: "5px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
          placeholder="Decimal"
          type="file"
          // value={tokenDecimal1}
          onChange={(e) => setFile(e.target.files[0])}
        />
      </Box>
      <Box align="center" mt={1}>
        <Button
          onClick={addCoin}
          style={{
            backgroundColor: "#69FFDB",
            borderRadius: "5px",
            height: "2rem",
          }}
          width={{ xs: "100%", md: "40%" }}
        >
          <Box fontWeight={700} fontSize={{ xs: "8px", md: "14px" }}>
            Add Coin
          </Box>
        </Button>
      </Box>
    </Grid>
  );
}
