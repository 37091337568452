import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  useTheme,
  Container,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  withStyles,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

import AddIcon from "@material-ui/icons/Add";

import axios from "axios";
import Slide from "@material-ui/core/Slide";
import Loading from "../loading";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { toast } from "react-toastify";
import { url, getTokenContract } from "../../../utils";
import AddApiCoin from "./AddapiCoin";
const useStyles = makeStyles((theme) => ({
  purple: {
    color: "#000000",
    backgroundColor: "#69FFDB",
    fontSize: "10px",
    fontWeight: 900,
    padding: "10px",
    border: "2px solid #DD1DE1",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
}));
export default function ApiCoins({
  data,
  setstatus,
  open,
  search,
  handleClose,
  setloading,
  init,
}) {
  const classes = useStyles();

  const [coinsSdata, setSCoins] = useState([]);
  const [addopen, setAddOpen] = React.useState(false);
  const [addapioins, setAddApiCoin] = React.useState({
    name: "",
    address: "0x0000000000000000000",
    symbol: "",
    decimals: "",
  });

  const handleClickOpen = (name, address, symbol, decimals) => {
    setAddOpen(true);
    setAddApiCoin({
      ...addapioins,
      name: name,
      address: address,
      symbol: symbol,
      decimals: decimals,
    });
  };

  const getTokens = async () => {
    try {
      const datagraph = await axios.post("https://graphql.bitquery.io", {
        query: `{
         ethereum(network: bsc) {
           address(address: {is: "0x1425844319d9a7a375c8f0d05c528948ca2fe3ce"}) {
             balances {
               currency {
                
                 symbol
                 address
                 name
                 decimals
               }
               value
             }
           }
         }
       }`,
      });
      const { data } = await axios.get(`${url}/coin/get`);
      const { data: data1 } = await axios.get(`${url}/admin/suggectedCoins`);
      var arr = datagraph.data.data.ethereum.address[0].balances;

      var result = [];
      for (let i = 0; i < arr?.length; i++) {
        var found = data.data?.find(
          (item) => item.tokenAddress == arr[i].currency.address
        );
        var found1 = data1.data?.find(
          (item) => item.tokenAddress == arr[i].currency.address
        );
        if (!found && !found1) {
          result.push(arr[i]);
        }
      }
      setSCoins(result);
      // });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (search) {
      const find = coinsSdata.filter(
        (v) =>
          v.currency.symbol.toLowerCase().includes(search.toLowerCase()) ||
          v.currency.address.toLowerCase().includes(search.toLowerCase())
      );
      setSCoins(find);
    } else {
      getTokens();
    }
  }, [search, open]);

  // const addCoin = async (name, address, symbol, decimals) => {
  //   setloading(true);
  //   const dataF = new FormData();
  //   dataF.append("tokenAddressname", name);
  //   dataF.append("tokenAddress", address);
  //   dataF.append("tokenSymbol", symbol);
  //   dataF.append("tokenDecimal", decimals);
  //   const { data } = await axios.post(`${url}/admin/suggested-coin`, dataF);
  //   if (data.status) {
  //     toast.success(data.message);
  //     setloading(false);
  //     init();
  //     handleClose();
  //   } else {
  //     setloading(false);
  //     toast.error(data.message);
  //   }
  // };
  return (
    <>
      <AddApiCoin
        addopen={addopen}
        setAddOpen={setAddOpen}
        apicoin={addapioins}
        init={init}
        setloading={setloading}
      />
      <Grid item xs={4} md={4}>
        <Box style={{ height: "400px", overflowY: "scroll" }}>
          {coinsSdata?.length !== 0 ? (
            coinsSdata?.map(
              ({ currency }) =>
                currency?.address?.length !== 1 && (
                  <Box
                    id="scrolling1"
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "center", md: "center" }}
                    flexDirection={{
                      xs: "column",
                      sm: "column",
                      md: "row",
                    }}
                    p={1}
                  >
                    <Box my={1}>
                      <Avatar className={classes.purple}>
                        {" "}
                        {currency?.symbol}
                      </Avatar>
                    </Box>
                    <Box
                      textAlign="center"
                      my={1}
                      fontWeight={700}
                      width="50%"
                      fontSize={{ xs: "14px", md: "14px" }}
                    >
                      {currency?.symbol}
                    </Box>
                    <Box width={{ xs: "60px", md: "90px" }}>
                      <Button
                        onClick={() =>
                          handleClickOpen(
                            currency?.name,
                            currency?.address,
                            currency?.symbol,
                            currency?.decimals
                          )
                        }
                        style={{
                          backgroundColor: "#69FFDB",
                          borderRadius: "5px",
                          height: "2rem",
                          width: "100%",
                        }}
                      >
                        <Box
                          fontWeight={700}
                          fontSize={{ xs: "8px", md: "13px" }}
                        >
                          Add Coin
                        </Box>
                      </Button>
                    </Box>
                  </Box>
                )
            )
          ) : (
            <Box align="center">
              <CircularProgress style={{ color: "#9931B1" }} />
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
}
