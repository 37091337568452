import React, { useState, useEffect } from "react";
import { Box, Button, useTheme, Fab, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
import Loading from "../loading";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { toast } from "react-toastify";
import { url } from "../../../utils";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function WelcomeMessageLock({ data, setstatus }) {
  const [open, setOpen] = React.useState(false);
  const [welcomeMessageLock, setwelcomeMessageLock] = React.useState(false);
  const [message, setmessage] = React.useState("");

  const { spacing } = useTheme();
  const [loading, setloading] = useState(false);
  const [id, setid] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const welcomeMessageLockHandler = async () => {
    if (welcomeMessageLock === "true" && !message) {
      toast.error("Enter welcomeMessage lock message first.");
    } else {
      setloading(true);
      setstatus(false);
      const { data } = await axios.post(`${url}/admin/welcomeMessage-lock`, {
        welcomeMessageLock: welcomeMessageLock === "true" ? true : false,
        welcomeMessage: message,
        _id: id,
      });
      toast.success(data.message);
      setstatus(true);
      setloading(false);
      setOpen(false);
    }
  };
  useEffect(() => {
    if (!Object.keys(data ? data : {}).length == 0) {
      setid(data._id);
      if (
        // data.welcomeMessageLock
        data.buybackLock
      ) {
        setwelcomeMessageLock("true");
      } else {
        setwelcomeMessageLock("false");
      }
      if (data.message) {
        setmessage(data.welcomeMessage);
      }
    }
  }, [data]);

  return (
    <>
      <Box component="h3" textAlign="left" fontSize={spacing(2)}>
        Welcome Message Lock:
      </Box>

      <Box
        component="h3"
        textAlign="left"
        fontSize={spacing(2)}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width={{ xs: "100%", md: "100%" }}
      >
        <Box width="40%">
          {data?.welcomeMessageLock ? "Locked" : "Unlocked"}
          <br />
          {data?.message ? data?.welcomeMessage : ""}
        </Box>
        <Fab
          size="medium"
          color="secondary"
          aria-label="edit"
          onClick={() => setOpen(true)}
        >
          <EditIcon />
        </Fab>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent
          position="relative"
          className="dialoge__content__section"
        >
          <Loading open={loading} />
          <Box position="absolute" right="0%" top="0%" textAlign="right" m={0}>
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ color: "#27CFA7" }} fontSize="small" />
            </IconButton>
          </Box>
          <Box
            component="h3"
            fontFamily="Avenir"
            fontWeight={700}
            fontSize={20}
            textAlign="center"
            my={2}
          >
            Welcome Message Lock:
          </Box>
          <Box my={spacing(0.5)} align="center">
            <select
              onChange={(e) => setwelcomeMessageLock(e.target.value)}
              value={welcomeMessageLock}
              style={{
                width: "100%",
                bgcolor: "#FFFFFF",
                fontSize: "bolder",
                border: "2px solid #DD1DE1",
                padding: "0.6rem 0.2rem",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                height: "40px",
              }}
            >
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </Box>
          {welcomeMessageLock === "true" ? (
            <Box my={spacing(0.5)} align="center">
              <textarea
                style={{
                  background: "#FFFFFF",
                  border: "2px solid #DD1DE1",
                  height: "40px",
                  outline: "none",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  padding: "5px",
                  width: "300px",
                  height: "100px",
                }}
                placeholder="welcomeMessage Lock Message"
                value={message}
                onChange={(e) => setmessage(e.target.value)}
              />
            </Box>
          ) : (
            ""
          )}
          <Box my={spacing(0.5)} align="center">
            <Button
              style={{
                backgroundColor: "#69FFDB",
                fontWeight: 700,
                height: "2rem",
              }}
              width={{ xs: "100%", md: "40%" }}
              fontWeight={700}
              onClick={welcomeMessageLockHandler}
            >
              Update
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
