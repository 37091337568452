import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  useTheme,
  Container,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  withStyles,
  Avatar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import CustomCoin from "./customCoin";
import AddIcon from "@material-ui/icons/Add";

import ApiCoins from "./apicoin";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
import Loading from "../loading";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { toast } from "react-toastify";
import AutomaticCoin from "./AutomaticCoin";
import { url, getTokenContract } from "../../../utils";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledModal = withStyles((theme) => ({
  root: {
    "& .MuiDialog-paperWidthLg": {
      width: "1000px",
    },
    "& .MuiDialog-paperScrollPaper": {
      width: "1000px",
      // height: "712px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "30px !important",
    },
    "& .MuiDialog-paperWidthSm": {
      // width: "1000px",
      // height: "972px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      // borderRadius: "30px !important",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
      // padding: "5px",
    },
    "& .dialoge__content__section": {
      // width: "1000px",
      padding: "0px !important",
      // backgroundColor: "rgba(117, 52, 226, 0.7)",
      // borderRadius: "23px",
      // width: "2053px",
      // width: "100%",
      // height: "100%",
    },
  },
}))(Dialog);
const useStyles = makeStyles((theme) => ({
  deletebtn: {
    background: "#FF0000",
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#fff",
    padding: ".2rem 1rem",
    "&:hover": {
      background: "#FF0000b1",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".7rem",
    },
  },
  selectOptions: {
    width: "100%",
    background: "#FFFFFF",
    fontSize: "bolder",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "0.6rem 0.2rem",
  },
  purple: {
    color: "#000000",
    backgroundColor: "#69FFDB",
    fontSize: "10px",
    fontWeight: 900,
    padding: "10px",
    border: "2px solid #DD1DE1",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  tdata: {
    width: "25%",
    height: "auto",
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "25px",
    lineHeight: "130%",
    padding: "2rem 1rem",
    color: "#000000",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".7rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".7rem",
    },
  },
}));
export default function SuggestedCoins({
  search: search1,
  setstatus,
  suggestedCoins,
  coinsSdata,
  init,
}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { spacing } = useTheme();
  const [loading, setloading] = useState(false);
  const [search, setsearch] = useState("");
  var smoothScroll = function () {
    var scrollContainer = document.getElementById("scrolling1");
    scrollContainer = scrollContainer.parentNode;
    scrollContainer.scrollTop += 40;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      setloading(true);
      setstatus(false);
      const { data } = await axios.post(`${url}/admin/delete`, {
        id: id,
      });
      toast.success(data.message);
      setstatus(true);
      setloading(false);
    } catch (error) {
      setloading(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Box component="h3" p={spacing(0.4)} position="relative">
        <Box component="h3" textAlign="left" fontSize={spacing(3)}>
          Add New Coins
          <Box position="absolute" right="5%" top="30px">
            <Fab
              size="medium"
              color="primary"
              aria-label="add"
              onClick={() => setOpen(true)}
            >
              <AddIcon />
            </Fab>
          </Box>
        </Box>

        <StyledModal
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          open={open}
          maxWidth="lg"
          height="90%"
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className="dialoge__content__section">
            <Loading open={loading} />
            <Box textAlign="right" my={0}>
              <IconButton position="relative" right="0%" onClick={handleClose}>
                <CloseIcon style={{ color: "#27CFA7" }} fontSize="large" />
              </IconButton>
            </Box>
            <Container maxWidth="lg">
              <Box
                bgcolor="#F5F5F5"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="10px"
                fontFamily="Avenir"
                mb={1}
                p={{ xs: "5px 10px 5px 10px", md: "5px 30px 5px 30px" }}
              >
                <Box
                  component="h3"
                  fontWeight={700}
                  fontSize={{ xs: 10, md: 15 }}
                  textAlign="center"
                  my={1}
                >
                  Network
                </Box>
                <Box
                  component="h3"
                  fontWeight={700}
                  fontSize={{ xs: 10, md: 15 }}
                  alignItems="center"
                  display="flex"
                  my={1}
                >
                  <Box>
                    <img
                      width={20}
                      src="/assets/BInancesmartchain.svg"
                      alt=""
                    />
                  </Box>
                  &nbsp; Smart Chain
                </Box>
              </Box>
              <Box
                bgcolor="#F5F5F5"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                borderRadius="10px"
                fontFamily="Avenir"
                p="10px 10px 10px 30px"
                mb={1}
              >
                <Box
                  component="h3"
                  fontWeight={700}
                  fontSize={15}
                  textAlign="center"
                  my={1}
                >
                  <SearchIcon />
                </Box>
                <Box
                  component="h3"
                  fontWeight={700}
                  fontSize={18}
                  textAlign="center"
                  display="flex"
                  my={0.5}
                  width="100%"
                >
                  <input
                    placeholder="Search by Name or Contract Address"
                    onChange={(e) => setsearch(e.target.value)}
                    value={search}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      outline: "none",
                      height: "100%",
                      width: "100%",
                      padding: "10px",
                    }}
                  />
                </Box>
              </Box>
              <Box
                bgcolor="#F5F5F5"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="10px"
                fontFamily="Avenir"
                position="relative"
                mb={1}
                p={{ xs: "5px 10px 5px 10px", md: "10px 30px 10px 30px" }}
              >
                <Box
                  component="h3"
                  fontWeight={700}
                  fontSize={{ xs: 10, md: 15 }}
                  width={{ xs: "40%", md: "50%" }}
                  textAlign="center"
                  my={1}
                >
                  Coin Name
                </Box>
                <Box
                  position="absolute"
                  left={{ xs: "36%", md: "32%" }}
                  onClick={() => smoothScroll()}
                >
                  <img
                    width={25}
                    src="/assets/coin-arrow.svg"
                    alt=""
                    style={{ cursor: "pointer" }}
                  />
                </Box>
                <Box
                  component="h3"
                  fontWeight={700}
                  fontSize={{ xs: 10, md: 15 }}
                  width="50%"
                  textAlign="center"
                  my={1}
                >
                  Add a Custom Coin / Token
                </Box>
                <Box
                  component="h3"
                  fontWeight={700}
                  fontSize={{ xs: 10, md: 15 }}
                  width="50%"
                  textAlign="center"
                  my={1}
                >
                  Suggested Coin Details
                </Box>
              </Box>

              <Grid container>
                <ApiCoins
                  init={init}
                  open={open}
                  search={search}
                  handleClose={handleClose}
                  setloading={setloading}
                />
                <CustomCoin
                  init={init}
                  handleClose={handleClose}
                  setloading={setloading}
                />
                <AutomaticCoin
                  init={init}
                  handleClose={handleClose}
                  setloading={setloading}
                />
              </Grid>
            </Container>
          </DialogContent>
        </StyledModal>

        <Grid direction="row">
          <table width="100%" style={{ borderSpacing: "0 1rem" }}>
            <tr
              style={{
                width: "100%",
                outline: "3px solid #2329D6",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <td className={classes.tdata} style={{ textAlign: "left" }}>
                #
              </td>
              <td className={classes.tdata}>Coin Logo</td>
              <td className={classes.tdata}>Coin Address</td>
              <td className={classes.tdata}>Coin Name</td>
              <td className={classes.tdata}>Coin Symbol</td>
              <td className={classes.tdata}>Action</td>
            </tr>
            {suggestedCoins.length !== 0 ? (
              suggestedCoins.map((item, i) => (
                <tr
                  style={{
                    width: "100%",
                    outline:
                      i % 2 !== 0 ? "3px solid #2329D6" : "3px solid #DD1DE1",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <td className={classes.tdata} style={{ display: "flex" }}>
                    {++i}
                  </td>
                  <td className={classes.tdata}>
                    <img
                      width="70"
                      height="70"
                      src={`${url}/${item.logoPath}`}
                    />
                  </td>
                  <td className={classes.tdata}>
                    {" "}
                    {item.tokenAddress.slice(0, 5) +
                      "..." +
                      item.tokenAddress.slice(-5)}
                  </td>
                  <td className={classes.tdata}> {item.tokenName}</td>
                  <td className={classes.tdata}> {item.tokenSymbol}</td>
                  <td className={classes.tdata}>
                    <Button
                      className={classes.deletebtn}
                      onClick={() => handleDelete(item._id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr
                textAlign="center"
                style={{
                  outline: "3px solid #DD1DE1",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
                mb={5}
              >
                {!search1 ? (
                  <td textAlign="center" colSpan={5} className={classes.tdata}>
                    No suggested coin added yet.
                  </td>
                ) : (
                  <td textAlign="center" colSpan={5} className={classes.tdata}>
                    No suggested coin found with this coin name "{search1}".
                  </td>
                )}
              </tr>
            )}
          </table>
        </Grid>
      </Box>
    </>
  );
}
