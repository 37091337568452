/*eslint-disable*/
import React, { useContext } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import SnackbarContent from "../../components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "../../components/Snackbar/Snackbar.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";

import axios from "axios";
import { AppContext, url } from "../../../utils.js";
import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Checkbox } from "@material-ui/core";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};
const useStyles = makeStyles(styles);

export default function Notifications() {
  const { account } = useContext(AppContext);

  const classes = useStyles();
  let { address } = useParams();

  const [users, setusers] = React.useState({});
  const [selected, setSelected] = React.useState([]);
  React.useEffect(() => {
    // Specify how to clean up after this effect:
    return function cleanup() {
      // to stop the warning of calling setState of unmounted component
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);
  const init = async () => {
    const res = await axios.get(`${url}/user/tickets/${address}`);

    setusers(res.data.data);
  };
  React.useEffect(() => {
    if (account) {
      init();
    }
  }, [account]);

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Notifications</h4>
        <p className={classes.cardCategoryWhite}>
          You can delete notifications of a specific user from here
        </p>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h5>User {address}</h5>
            <br />

            {users?.notification?.map(({ message, addedAt, _id }, i) => (
              <>
                <SnackbarContent
                  message={message}
                  address={address}
                  id={_id}
                  date={addedAt}
                  key={i}
                  del={true}
                  icon={AddAlert}
                  init={init}
                />
              </>
            ))}
            {users?.notification?.length == 0 && (
              <Box pl={1}>No notification sent to this user yet.</Box>
            )}
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}
