import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Main from "./Main";
import "./admin-dashboard/assets/css/material-dashboard-react.css?v=1.10.0";
import "./user-dashboard/assets/css/material-dashboard-react.css?v=1.10.0";
import { AppContextProvider } from "./utils";
import { hydrate, render } from "react-dom";
import LoadingSuspence from "./LoadingSuspense";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <>
//     <AppContextProvider>
//       <ToastContainer />
//       <Main />
//     </AppContextProvider>
//   </>
// );
const renderLoader = () => <LoadingSuspence />;

const Wraper = (
  <AppContextProvider>
    <ToastContainer />
    <Suspense fallback={renderLoader()}>
      <Main />
    </Suspense>
  </AppContextProvider>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(Wraper, rootElement);
} else {
  render(Wraper, rootElement);
}

reportWebVitals();
