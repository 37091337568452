import React, { useState, useEffect, useContext } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Table from "../../components/Table/Table.js";
import Tasks from "../../components/Tasks/Tasks.js";
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";
// import Danger from "components/Typography/Danger.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { AppContext, url } from "../../../utils";
import { useCrypopartyContract } from "../../../ConectivityAssests/hooks/Hooks.js";
import Button from "../../components/CustomButtons/Button.js";

import { bugs, website, server } from "../../variables/general.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "../../variables/charts.js";
import logo from "../../assets/img/reactlogo.svg";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import axios from "axios";

import { Box } from "@material-ui/core";
import { parseUnits, formatUnits } from "@ethersproject/units";
import { toast } from "react-toastify";
import Loading from "../loading.jsx";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const { account } = useContext(AppContext);

  const [referalDetail, setreferalDetail] = useState({
    referal: 0,
    refferredUsersticketsBought: 0,
  });
  const classes = useStyles();
  const [coin, setcoin] = useState(0);
  const [scoin, setscoin] = useState(0);
  const [coinsAdded, setcoinsAdded] = useState(0);
  const [coinsVoted, setcoinsVoted] = useState(0);
  const [refer, setrefer] = useState(0);
  const [product, setproduct] = useState(0);
  const [orders, setorders] = useState([]);
  const [bnbbuybackReward, setbnbbuybackReward] = React.useState(0);
  const [busdbuybackReward, setbusdbuybackReward] = React.useState(0);
  const cryptopartyContract = useCrypopartyContract();
  const [sold, setsold] = useState(0);
  const [total, settotal] = useState(0);
  const [lcpReward, setlcpReward] = React.useState(0);
  const [totalBnbreward, settotalBnbreward] = useState(0);
  const [totalBusdreward, settotalBusdreward] = useState(0);
  const [totallcpreward, settotallcpreward] = useState(0);
  const [loading, setloading] = useState(false);
  const init1 = async () => {
    try {
      const { ticketsOwned } = await cryptopartyContract.customers(account);
      const solds = (await cryptopartyContract.TickesBoughtGlobal()).toNumber();
      const {
        refbonusbnb,
        refbonusbusd,
        claimableLCP,
        TotalLCP,
        totalrefbonusbnb,
        totalrefbonusbusd,
      } = await cryptopartyContract.customers(account);
      setbnbbuybackReward(formatUnits(refbonusbnb.toString()));
      setbusdbuybackReward(formatUnits(refbonusbusd.toString()));
      setlcpReward(+claimableLCP);
      settotalBnbreward(formatUnits(totalrefbonusbnb.toString()));
      settotalBusdreward(formatUnits(totalrefbonusbusd.toString()));
      settotallcpreward(+TotalLCP);
      const { data } = await axios.get(`${url}/user/tickets/${account}`);
      const { data: data1 } = await axios.get(
        `${url}/coin/get-added-coin/?userWalletAddress=${account}`
      );
      const { data: data2 } = await axios.get(`${url}/user/tickets/${account}`);
      setcoinsVoted(
        data2?.data?.coinsVoted?.length ? data2?.data?.coinsVoted?.length : 0
      );
      settotal(parseInt(ticketsOwned.toString()));

      setsold(
        parseInt(ticketsOwned.toString()) -
          (data?.data?.usedTickets ? +data?.data?.usedTickets : 0)
      );
      setcoinsAdded(data1.data.length);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (cryptopartyContract) {
      init1();
    }
  }, [cryptopartyContract]);
  useEffect(() => {
    const init = async () => {
      const { data } = await axios.get(
        `${url}/referral/total?userWalletAddress=${account}`
      );
      let { data: data1 } = data;
      if (data1.length !== 0) {
        let obj = {
          referal: data1[0]?.refferredUsers,
          refferredUsersticketsBought: data1[0]?.refferredUsersticketsBought,
        };
        setreferalDetail(obj);
      }

      const res = await axios.get(
        `${url}/order/get-orders?userWalletAddress=${account}`
      );
      setorders([...res.data.data]);
    };
    init();
  }, []);
  const handleClaimRewardsBNB = async () => {
    try {
      setloading(true);
      const tx = await cryptopartyContract.claimRefBonusBNB();
      const reciept = await tx.wait();
      init1();
      setloading(false);
      toast.success("BNB claimed successfully.");
    } catch (error) {
      if (error?.data?.message) {
        toast.error(error?.data?.message);
      } else {
        toast.error(error?.message);
      }
      setloading(false);
    }
  };

  const handleClaimRewardsBUSD = async () => {
    try {
      setloading(true);
      const tx = await cryptopartyContract.claimRefBonusBUSD();
      const reciept = await tx.wait();
      init1();

      toast.success("BUSD claimed successfully.");
      setloading(false);
    } catch (error) {
      if (error?.data?.message) {
        toast.error(error?.data?.message);
      } else {
        toast.error(error?.message);
      }
      setloading(false);
    }
  };
  const handleClaimRewardsLCP = async () => {
    try {
      setloading(true);
      const tx = await cryptopartyContract.claimLCP();
      const reciept = await tx.wait();
      toast.success("LCP claimed successfully.");
      init1();
      setloading(false);
    } catch (error) {
      if (error?.data?.message) {
        toast.error(error?.data?.message);
      } else {
        toast.error(error?.message);
      }
      setloading(false);
    }
  };

  return (
    <div>
      <Loading open={loading} />
      <h4>Your Earnings</h4>
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader stats icon>
              <CardIcon color="white">
                <img width="50px" src="/assets/BInancesmartchain.svg" />
              </CardIcon>
              <p className={classes.cardCategory}>Claim BNB</p>
              <h3 className={classes.cardTitle}>
                <small>{bnbbuybackReward}</small>
              </h3>
            </CardHeader>
            <CardFooter stats styles>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                width="100%"
              >
                <p>
                  Claimed :{" "}
                  <span
                    style={{
                      background: "gray",
                      padding: "5px 20px",
                      borderRadius: "5px",
                      color: "#fff",
                      fontWeight: 700,
                    }}
                  >
                    {" "}
                    {+totalBnbreward - +bnbbuybackReward}
                  </span>
                </p>
                <Button
                  color="warning"
                  onClick={handleClaimRewardsBNB}
                  size="sm"
                >
                  Claim
                </Button>
              </Box>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="white">
                <img width="50px" src="/assets/BUSD.png" />
              </CardIcon>
              <p className={classes.cardCategory}>Claim BUSD</p>
              <h3 className={classes.cardTitle}>
                <small>{busdbuybackReward}</small>
              </h3>
            </CardHeader>
            <CardFooter stats styles>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                width="100%"
              >
                <p>
                  Claimed :{" "}
                  <span
                    style={{
                      background: "gray",
                      padding: "5px 20px",
                      borderRadius: "5px",
                      color: "#fff",
                      fontWeight: 700,
                    }}
                  >
                    {+totalBusdreward - +busdbuybackReward}
                  </span>
                </p>
                <Button
                  color="danger"
                  onClick={handleClaimRewardsBUSD}
                  size="sm"
                >
                  Claim
                </Button>
              </Box>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="white">
                <img
                  width="60px"
                  style={{ margin: "10px 0px 10px 0px" }}
                  src="/logo.png"
                />
              </CardIcon>
              <p className={classes.cardCategory}>Claim LCP</p>
              <h3 className={classes.cardTitle}>
                <small>{lcpReward.toFixed(1)}</small>
              </h3>
            </CardHeader>
            <CardFooter stats styles>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                width="100%"
              >
                <p>
                  Claimed :{" "}
                  <span
                    style={{
                      background: "gray",
                      padding: "5px 20px",
                      borderRadius: "5px",
                      color: "#fff",
                      fontWeight: 700,
                    }}
                  >
                    {" "}
                    {totallcpreward - lcpReward}
                  </span>
                </p>
                <Button
                  color="primary"
                  onClick={handleClaimRewardsLCP}
                  size="sm"
                >
                  Claim
                </Button>
              </Box>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <i className="fad fa-users-crown"></i>
              </CardIcon>
              <p className={classes.cardCategory}>No of Referrals</p>
              <h3 className={classes.cardTitle}>
                <small>{referalDetail.referal}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="danger">
                <i class="fad fa-ticket-alt"></i>
              </CardIcon>
              <p className={classes.cardCategory}>Your Referrals tickets</p>
              <h3 className={classes.cardTitle}>
                <small>{referalDetail.refferredUsersticketsBought}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="primary">
                <i class="fad fa-ticket-alt"></i>
              </CardIcon>
              <p className={classes.cardCategory}>Your Total Tickets</p>
              <h3 className={classes.cardTitle}>
                {" "}
                <small>{total}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <i class="fad fa-ticket-alt"></i>
              </CardIcon>
              <p className={classes.cardCategory}>Your Remaining Tickets</p>
              <h3 className={classes.cardTitle}>
                {" "}
                <small>{sold}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="warning">
                <i className="fad fa-coin"></i>
              </CardIcon>
              <p className={classes.cardCategory}>Coins Added</p>
              <h3 className={classes.cardTitle}>
                {" "}
                <small>{coinsAdded}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <i className="fad fa-coin"></i>
              </CardIcon>
              <p className={classes.cardCategory}>Coins Voted</p>
              <h3 className={classes.cardTitle}>
                {" "}
                <small>{coinsVoted}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                just now
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <i className="fad fa-box"></i>
              </CardIcon>
              <p className={classes.cardCategory}>Products Redeemed</p>
              <h3 className={classes.cardTitle}>
                {" "}
                <small>{orders.length}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
