import React, { useState, useEffect } from "react";
import { Box, useTheme, Grid, makeStyles, withStyles } from "@material-ui/core";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledModal = withStyles((theme) => ({
  root: {
    "& .MuiDialog-paperWidthLg": {
      width: "1000px",
    },
    "& .MuiDialog-paperScrollPaper": {
      width: "1000px",
      // height: "712px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "30px !important",
    },
    "& .MuiDialog-paperWidthSm": {
      // width: "1000px",
      // height: "972px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      // borderRadius: "30px !important",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
      // padding: "5px",
    },
    "& .dialoge__content__section": {
      // width: "1000px",
      padding: "0px !important",
      // backgroundColor: "rgba(117, 52, 226, 0.7)",
      // borderRadius: "23px",
      // width: "2053px",
      // width: "100%",
      // height: "100%",
    },
  },
}))(Dialog);
const useStyles = makeStyles((theme) => ({
  deletebtn: {
    background: "#FF0000",
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#fff",
    padding: ".2rem 1rem",
    "&:hover": {
      background: "#FF0000b1",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".7rem",
    },
  },
  selectOptions: {
    width: "100%",
    background: "#FFFFFF",
    fontSize: "bolder",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "0.6rem 0.2rem",
  },
  tdata: {
    width: "25%",
    height: "auto",
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "25px",
    lineHeight: "130%",
    padding: "2rem 1rem",
    color: "#000000",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".7rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".7rem",
    },
  },
}));
export default function SuggestedCoins({ search, setstatus, coinsVoted }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const { spacing } = useTheme();

  return (
    <>
      <Box component="h3" p={spacing(0.4)} position="relative">
        <Grid direction="row">
          <table width="100%" style={{ borderSpacing: "0 1rem" }}>
            <tr
              style={{
                width: "100%",
                outline: "3px solid #2329D6",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <td className={classes.tdata} style={{ textAlign: "left" }}>
                Rank
              </td>
              <td className={classes.tdata}>Coin Name</td>
              <td className={classes.tdata}>Votes</td>
              <td className={classes.tdata}>You Voted</td>
            </tr>
            {coinsVoted?.length !== 0 ? (
              coinsVoted?.map((item, i) => (
                <tr
                  style={{
                    width: "100%",
                    outline:
                      i % 2 !== 0 ? "3px solid #2329D6" : "3px solid #DD1DE1",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <td className={classes.tdata} style={{ display: "flex" }}>
                    {++i}
                  </td>
                  <td className={classes.tdata}> {item.name}</td>
                  <td className={classes.tdata}> {item.votes}</td>
                  <td className={classes.tdata}> {item.yvotes}</td>
                </tr>
              ))
            ) : (
              <tr>
                {!search ? (
                  <td
                    textAlign="center"
                    colspan="6"
                    className={classes.tdata}
                    style={{
                      border: "3px solid #DD1DE1",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    No coin voted by you yet.
                  </td>
                ) : (
                  <td
                    textAlign="center"
                    colspan="6"
                    className={classes.tdata}
                    style={{
                      border: "3px solid #DD1DE1",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    No coin found with this coin name "{search}".
                  </td>
                )}
              </tr>
            )}
          </table>
        </Grid>
      </Box>
    </>
  );
}
