import React from "react";
import { Contract } from "@ethersproject/contracts";

import Web3 from "web3";
import { BigNumber } from "@ethersproject/bignumber";
import { MaxUint256 } from "@ethersproject/constants";
import { Web3Provider } from "@ethersproject/providers";
import { parseUnits } from "@ethersproject/units";
import {
  tokenAddress,
  oldStakingAddress,
  cryptopartyAddress,
  LCPtokenAddress,
  BUSDAddress,
  presaletoken,
  presaleContract,
  stakingAddress,
  stakeTestingToken,
} from "../utilities/Environment";
import oldStakingAbi from "../utilities/oldStakingAbi.json";
import token_abi from "../utilities/token_abi.json";
import busd_abi from "../utilities/busd_abi.json";
import lcp_token_abi from "../utilities/lcp_Token_abi.json";
import cryptoparty_abi from "../utilities/cryptoparty_abi.json";
import presaletokenabi from "../utilities/preSaleToken.json";
import presaleabi from "../utilities/presaleContract.json";
import stakingAbi from "../utilities/stakingAbi.json";
import stakeTestingAbi from "../utilities/stakeTestingAbi.json";
const web3 = new Web3(
  Web3.givenProvider || "https://bsc-dataseed.binance.org/"
);

function useContract(address, ABI, signer) {
  return React.useMemo(() => {
    if (signer) {
      return new Contract(address, ABI, signer);
    } else {
      return "error";
    }
  }, [address, ABI, signer]);
}

export function useTokenContract(signer) {
  try {
    return useContract(BUSDAddress, token_abi, signer);
  } catch (e) {
    return "";
  }
}
export function useLCPTokenContract(signer) {
  try {
    return useContract(LCPtokenAddress, lcp_token_abi, signer);
  } catch (e) {
    return "";
  }
}
export function useBUSDContract(signer) {
  try {
    return useContract(BUSDAddress, busd_abi, signer);
  } catch (e) {
    return "";
  }
}
export function useCrypopartyContract(signer) {
  try {
    return useContract(cryptopartyAddress, cryptoparty_abi, signer);
  } catch (e) {
    return "";
  }
}
export function useStakingContract(signer) {
  try {
    return useContract(stakingAddress, stakingAbi, signer);
  } catch (e) {
    return "";
  }
}
export function useOldStakingContract(signer) {
  try {
    return useContract(oldStakingAddress, oldStakingAbi, signer);
  } catch (e) {
    return "";
  }
}
export function useStakingContract1() {
  try {
    const StakingContract = new web3.eth.Contract(stakingAbi, stakingAddress);
    return StakingContract;
  } catch (err) {
    return "";
  }
}
export function useCrypopartyContract1() {
  try {
    const CrypopartyContract = new web3.eth.Contract(
      cryptoparty_abi,
      cryptopartyAddress
    );
    return CrypopartyContract;
  } catch (err) {
    return "";
  }
}
export function usePresaleTokenContract(signer) {
  return useContract(presaletoken, presaletokenabi, signer);
}
export function usePresaleTokenContract1() {
  try {
    const PresaleTokenContract = new web3.eth.Contract(
      presaletokenabi,
      presaletoken
    );
    return PresaleTokenContract;
  } catch (err) {
    return "";
  }
}
export function useTestingTokenContract(signer) {
  return useContract(stakeTestingToken, stakeTestingAbi, signer);
}
export function useTestingTokenContract1() {
  try {
    const TestingTokenContract = new web3.eth.Contract(
      stakeTestingAbi,
      stakeTestingToken
    );
    return TestingTokenContract;
  } catch (err) {
    return "";
  }
}
export let usePresaleContract = (signer) =>
  useContract(presaleContract, presaleabi, signer);

export function usePresaleContract1() {
  const presaleContract1 = new web3.eth.Contract(presaleabi, presaleContract);
  return presaleContract1;
}

export function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}
// add 10%
export function calculateGasMargin(value) {
  return +(
    (value * BigNumber.from(10000).add(BigNumber.from(1000))) /
    BigNumber.from(10000)
  ).toFixed(0);
  // return value;
}
//CalculatePayableGas
export const gasEstimationPayable = async (active, fn, ref, amount) => {
  if (active) {
    const estimateGas = await fn(ref, MaxUint256).catch(() => {
      return fn(ref, { value: parseUnits(amount.toString()).toString() });
    });
    return calculateGasMargin(estimateGas);
  }
};
export function getTokenContract(address, signer) {
  return new Contract(address, token_abi, signer);
}

export const url =
  process.env.NODE_ENV === "production" ? "" : "http://localhost:4000";
