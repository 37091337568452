import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import routes from "../../routes";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "../assets/img/sidebar-2.jpg";
import logo from "../assets/img/reactlogo.svg";
import { useRouteName } from "../hooks/useRouteName";
import NotificationView from "../views/Notifications/notification-view";
import Loading from "../../loading";
// core components
const Navbar = React.lazy(() => import("../components/Navbars/Navbar"));
const Footer = React.lazy(() => import("../components/Footer/Footer.js"));
const Sidebar = React.lazy(() => import("../components/Sidebar/Sidebar.js"));
const FixedPlugin = React.lazy(() =>
	import("../components/FixedPlugin/FixedPlugin.js")
);
const Addnew = React.lazy(() => import("../views/Products/Addnew.js"));
const EditProduct = React.lazy(() =>
	import("../views/Products/EditProduct.js")
);
const View = React.lazy(() => import("../views/orders/View"));

let ps;
const switchRoutes = (
	<>
		{routes.map((prop, key) => {
			if (prop.layout === "/admin") {
				return (
					<Route
						exact
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			}
			return null;
		})}
		{/* <Redirect from="/admin" to="/admin/dashboard" /> */}
		<Route exact path="/admin/add-new-product">
			<Suspense fallback={<Loading loading={true} />}>
				<Addnew />
			</Suspense>
		</Route>
		<Route exact path="/admin/edit-product/:id">
			<Suspense fallback={<Loading loading={true} />}>
				<EditProduct />
			</Suspense>
		</Route>
		<Route exact path="/admin/view-notifications/:address">
			<Suspense fallback={<Loading loading={true} />}>
				<NotificationView />
			</Suspense>
		</Route>
		<Route exact path="/admin/view/:id/:productId">
			<Suspense fallback={<Loading loading={true} />}>
				<View />
			</Suspense>
		</Route>
	</>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
	// styles
	let routeName1 = useRouteName();
	const classes = useStyles();
	// ref to help us initialize PerfectScrollbar on windows devices
	const mainPanel = React.createRef();
	// states and functions
	const [image, setImage] = React.useState(bgImage);
	const [color, setColor] = React.useState("blue");
	const [fixedClasses, setFixedClasses] = React.useState("dropdown");
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [routename, setroutename] = React.useState("");
	const handleImageClick = (image) => {
		setImage(image);
	};
	const handleColorClick = (color) => {
		setColor(color);
	};
	const handleFixedClick = () => {
		if (fixedClasses === "dropdown") {
			setFixedClasses("dropdown show");
		} else {
			setFixedClasses("dropdown");
		}
	};
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const getRoute = () => {
		return window.location.pathname !== "/admin/maps";
	};
	const resizeFunction = () => {
		if (window.innerWidth >= 960) {
			setMobileOpen(false);
		}
	};
	// initialize and destroy the PerfectScrollbar plugin
	React.useEffect(() => {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(mainPanel.current, {
				suppressScrollX: true,
				suppressScrollY: false,
			});
			document.body.style.overflow = "hidden";
		}
		window.addEventListener("resize", resizeFunction);
		// Specify how to clean up after this effect:
		return function cleanup() {
			if (navigator.platform.indexOf("Win") > -1) {
				ps.destroy();
			}
			window.removeEventListener("resize", resizeFunction);
		};
	}, [mainPanel]);
	React.useEffect(() => {
		setroutename(routeName1);
	}, []);
	return (
		<div className={classes.wrapper}>
			<Suspense fallback={<Loading loading={true} />}>
				<Sidebar
					routes={routes}
					logoText={"Admin"}
					logo={logo}
					image={image}
					handleDrawerToggle={handleDrawerToggle}
					open={mobileOpen}
					color={color}
					setroutename={setroutename}
					{...rest}
				/>
			</Suspense>
			<div className={classes.mainPanel} ref={mainPanel}>
				<Suspense fallback={<Loading loading={true} />}>
					<Navbar
						routes={routes}
						handleDrawerToggle={handleDrawerToggle}
						{...rest}
					/>
				</Suspense>
				{/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
				{getRoute() ? (
					<Suspense fallback={<Loading loading={true} />}>
						<div className={classes.content}>
							<div className={classes.container}>{switchRoutes}</div>
						</div>
					</Suspense>
				) : (
					<Suspense fallback={<Loading loading={true} />}>
						<div className={classes.map}>{switchRoutes}</div>
					</Suspense>
				)}
				{/* {getRoute() ? <Footer /> : null} */}
				<Suspense fallback={<Loading loading={true} />}>
					<FixedPlugin
						handleImageClick={handleImageClick}
						handleColorClick={handleColorClick}
						bgColor={color}
						bgImage={image}
						handleFixedClick={handleFixedClick}
						fixedClasses={fixedClasses}
					/>
				</Suspense>
			</div>
		</div>
	);
}
