import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { url } from "../../../utils";
import TableCell from "@material-ui/core/TableCell";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import Notify from "./Notify";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import { FcOk, FcDisclaimer } from "react-icons/fc";
import { RiDeleteBin2Fill } from "react-icons/ri";

const useStyles = makeStyles(styles);

function CoinsAdd({ init }) {
  const [coinsAdded, setcoinsAdded] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    const coins = async (wallet) => {
      const { data } = await axios.get(
        `${url}/coin/get-added-coin/?userWalletAddress=${wallet}`
      );

      setcoinsAdded(data.data.length);
    };
    // if (address) {
    //   coins(address);
    // }
  }, []);
  return (
    <TableCell className={classes.tableCell} key={coinsAdded}>
      {coinsAdded}
    </TableCell>
  );
}

export default function CustomTable(props) {
  const { tableHead, tableData, tableHeaderColor, search, init } = props;
  const classes = useStyles();
  const suspendHandler = async (id, suspend) => {
    try {
      const { data } = await axios.post(`${url}/coin/suspend-coin`, {
        id,
        suspend,
      });
      if (data.status) {
        toast.success(data.message);
        init();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const deleteHandler = async (id) => {
    try {
      const { data } = await axios.post(`${url}/coin/delete-coin`, {
        id,
      });
      if (data.status) {
        toast.success(data.message);
        init();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData?.length !== 0 ? (
            tableData.map(
              (
                {
                  tokenAddress,
                  name,
                  userWalletAddress,
                  addedAt,
                  rank,
                  votes,
                  _id,
                  suspendStatus,
                },
                key
              ) => (
                <TableRow key={key} className={classes.tableBodyRow}>
                  <TableCell className={classes.tableCell} key={key}>
                    {tokenAddress}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {name}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {userWalletAddress}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {moment(addedAt).format("LLL")}
                  </TableCell>
                  {/* <CoinsAdd address={address} /> */}

                  <TableCell className={classes.tableCell} key={key}>
                    {rank}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {votes}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {suspendStatus ? (
                      <IconButton onClick={() => suspendHandler(_id, false)}>
                        <FcOk />
                      </IconButton>
                    ) : (
                      <IconButton>
                        <FcDisclaimer
                          onClick={() => suspendHandler(_id, true)}
                        />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    <IconButton onClick={() => deleteHandler(_id)}>
                      <RiDeleteBin2Fill style={{ color: "red" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <TableRow className={classes.tableBodyRow}>
              {!search ? (
                <TableCell
                  colSpan="8"
                  align="center"
                  className={classes.tableCell}
                >
                  No coin is added by user yet
                </TableCell>
              ) : (
                <TableCell
                  colSpan="8"
                  align="center"
                  className={classes.tableCell}
                >
                  No coin is found with this "{search}"
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
