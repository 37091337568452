import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  useTheme,
  Container,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  withStyles,
  Avatar,
} from "@material-ui/core";

import axios from "axios";
import { toast } from "react-toastify";
import { url, getTokenContract, AppContext } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  deletebtn: {
    background: "#FF0000",
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#fff",
    padding: ".2rem 1rem",
    "&:hover": {
      background: "#FF0000b1",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".7rem",
    },
  },
  selectOptions: {
    width: "100%",
    background: "#FFFFFF",
    fontSize: "bolder",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "0.6rem 0.2rem",
  },
  purple: {
    color: "#000000",
    backgroundColor: "#69FFDB",
    fontSize: "10px",
    fontWeight: 900,
    padding: "10px",
    border: "2px solid #DD1DE1",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  tdata: {
    width: "25%",
    height: "auto",
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "25px",
    lineHeight: "130%",
    padding: "2rem 1rem",
    color: "#000000",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".7rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".7rem",
    },
  },
}));
export default function AutomaticCoin({ handleClose, setloading, init }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { signer } = useContext(AppContext);

  const [tokenAddressname, setTokenAddressName] = useState("");
  const [tokenSymbol, settokenSymbol] = useState("");
  const [tokenDecimal, settokenDecimal] = useState("");
  const [tokenAddress, settokenAddress] = useState("");
  var [file, setfile] = useState("");
  const { spacing } = useTheme();

  const handleTokenAddress = async (e) => {
    const value = e.target.value.trim();
    settokenAddress(value);
    try {
      if (value && +value?.length == 42) {
        setloading(true);
        const tokenContract = getTokenContract(value, signer);

        const name = await tokenContract.name();
        const symbol = await tokenContract.symbol();
        const decimal = await tokenContract.decimals();
        setTokenAddressName(name);
        settokenSymbol(symbol);
        settokenDecimal(decimal);
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      console.error("errrrro=>", error);
    }
  };

  const addCoin = async () => {
    if (!tokenAddress) {
      toast.error("please enter token address first.");
    } else if (!tokenAddressname) {
      toast.error("Token Address is invalid");
    } else {
      setloading(true);
      const dataF = new FormData();
      dataF.append("file", file);
      dataF.append("tokenAddressname", tokenAddressname);
      dataF.append("tokenAddress", tokenAddress);
      dataF.append("tokenSymbol", tokenSymbol);
      dataF.append("tokenDecimal", tokenDecimal);
      const { data } = await axios.post(`${url}/admin/suggested-coin`, dataF);
      if (data.status) {
        toast.success(data.message);
        setloading(false);
        init();
        handleClose();
      } else {
        setloading(false);
        toast.error(data.message);
      }
    }
  };

  return (
    <Grid item xs={4} md={4} align="right" mt={2}>
      <Box mb={0.5} mt={1} align="center" p={1}>
        <input
          style={{
            background: "#FFFFFF",
            border: "1px solid #DD1DE1",
            fontSize: "18px",
            height: "40px",
            outline: "none",
            borderRadius: "5px",
            width: "100%",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
          placeholder="Contract Address"
          value={tokenAddress}
          onChange={handleTokenAddress}
        />
      </Box>
      <Box mb={0.5} align="center" p={1}>
        <input
          style={{
            background: "#FFFFFF",
            border: "1px solid #2329D6",
            fontSize: "18px",
            height: "40px",
            width: "100%",
            outline: "none",
            borderRadius: "5px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
          placeholder="Name"
          value={tokenAddressname}
          readOnly
        />
      </Box>
      <Box mb={0.5} align="center" p={1}>
        <input
          style={{
            background: "#FFFFFF",
            border: "1px solid #DD1DE1",
            fontSize: "18px",
            height: "40px",
            outline: "none",
            width: "100%",
            borderRadius: "5px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
          placeholder="Symbol"
          value={tokenSymbol}
          readOnly
        />
      </Box>
      <Box mb={0.5} align="center" p={1}>
        <input
          style={{
            background: "#FFFFFF",
            border: "1px solid #2329D6",
            fontSize: "18px",
            height: "40px",
            outline: "none",
            width: "100%",
            borderRadius: "5px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
          placeholder="Decimal"
          value={tokenDecimal}
          readOnly
        />
      </Box>

      <Box my={spacing(0)} align="center">
        <Box
          component="h3"
          fontFamily="Avenir"
          fontWeight={700}
          fontSize={20}
          textAlign="center"
          my={0}
        >
          Token Logo
        </Box>
        <input
          style={{
            background: "#FFFFFF",
            border: "2px solid #DD1DE1",
            height: "40px",
            outline: "none",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            padding: "5px",
          }}
          type="file"
          onChange={(e) => setfile(e.target.files[0])}
        />
      </Box>
      <Box align="center" mt={1}>
        <Button
          onClick={addCoin}
          style={{
            backgroundColor: "#69FFDB",
            borderRadius: "5px",
            height: "2rem",
          }}
          width={{ xs: "100%", md: "40%" }}
        >
          <Box fontWeight={700} fontSize={{ xs: "8px", md: "14px" }}>
            Add Coin
          </Box>
        </Button>
      </Box>
    </Grid>
  );
}
