import React, { useContext, useEffect, useState } from "react";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { defaultAddress } from "./ConectivityAssests/utilities/Environment.js";
import { Box, useMediaQuery } from "@material-ui/core";
import Web3 from "web3";
import Admin from "./admin-dashboard/layouts/Admin.js";
import User from "./user-dashboard/layouts/User.js";
import App from "./App";
import { AppContext } from "./utils.js";

export default function Main() {
	const { account } = useContext(AppContext);
	const [switchNetwork, setswitchNetwork] = useState(false);
	const web3 = new Web3(
		Web3.givenProvider || "https://bsc-dataseed.binance.org/"
	);

	return (
		<Box position="relative">
			<BrowserRouter>
				<Switch>
					<Route path="/admin">
						{account &&
						account.toLowerCase() === defaultAddress.toLowerCase() ? (
							<Admin />
						) : (
							<Redirect to="/" />
						)}
					</Route>
					<Route path="/user">{account ? <User /> : <Redirect to="/" />}</Route>
					<Route path="/">
						<App />
					</Route>
				</Switch>
			</BrowserRouter>
		</Box>
	);
}
