import React, { useState, useEffect, useContext } from "react";
// @material-ui/core ../../components
import { makeStyles } from "@material-ui/core/styles";
// core ../../components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";

import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import styles1 from "../../assets/jss/material-dashboard-react/components/tasksStyle.js";

import { AppContext, url } from "../../../utils";
import axios from "axios";
import CoinsTable from "./CoinsTable&Modal";

import CustomInput from "../../components/CustomInput/CustomInput.js";
import { Box, Button } from "@material-ui/core";
import { Search } from "@material-ui/icons";
const styles = (theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  deletebtn: {
    background: "#FF0000",
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#fff",
    padding: ".2rem 1rem",
    "&:hover": {
      background: "#FF0000b1",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".7rem",
    },
  },
  selectOptions: {
    width: "100%",
    background: "#FFFFFF",
    fontSize: "bolder",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "0.6rem 0.2rem",
  },
  tdata: {
    width: "25%",
    height: "auto",
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "25px",
    lineHeight: "130%",
    padding: "2rem 1rem",
    color: "#000000",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".7rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".7rem",
    },
  },
});

const useStyles = makeStyles({ ...styles, ...styles1 });

export default function Variables() {
  const classes = useStyles();
  const { account } = useContext(AppContext);

  const [status, setstatus] = useState(false);
  const [suggestedCoins, setsuggestedCoins] = useState([]);
  const [coins, setcoins] = useState([]);
  const [coinsAdded, setcoinsAdded] = useState([]);
  const [search, setsearch] = useState("");

  useEffect(() => {
    const init = async () => {
      const { data: data1 } = await axios.get(
        `${url}/coin/get-added-coin/?userWalletAddress=${account}`
      );
      setcoinsAdded(data1.data);
    };

    init();
  }, [account]);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Coins added by you</h4>
            </CardHeader>
            <CardBody>
              <Box align="right" className={classes.searchWrapper}>
                <CustomInput
                  value={search}
                  onChange={(e) => setsearch(e.target.value)}
                  formControlProps={{
                    className: +classes.search,
                  }}
                  inputProps={{
                    placeholder: "Coin Name",
                    inputProps: {
                      "aria-label": "Search",
                    },
                  }}
                />
                <Button color="white" aria-label="edit" justIcon round>
                  <Search />
                </Button>
              </Box>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CoinsTable
                    coinsAdded={coinsAdded.filter((v) =>
                      v.name.toLowerCase().includes(search.toLowerCase())
                    )}
                    setstatus={setstatus}
                    search={search}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
