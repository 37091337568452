import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { url } from "../../../utils";
import TableCell from "@material-ui/core/TableCell";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import styles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import Notify from "./Notify";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { Box, Checkbox, IconButton } from "@material-ui/core";
import { BiDetail } from "react-icons/bi";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useCrypopartyContract } from "../../../ConectivityAssests/hooks/Hooks";
import BullkNotify from "./BulkNotify";

const TableCellD = ({ account }) => {
  const cryptopartyContract = useCrypopartyContract();
  const [cell, setCell] = useState("");

  React.useEffect(() => {
    (async () => {
      try {
        const { ticketsOwned } =
          (await cryptopartyContract) &&
          cryptopartyContract?.customers(account);
        setCell(ticketsOwned);
      } catch (error) {}
    })();
  }, [account, cryptopartyContract]);
  return <>{+cell}</>;
};

const useStyles = makeStyles(styles);

function CoinsAdd({ address }) {
  const [coinsAdded, setcoinsAdded] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    const coins = async (wallet) => {
      const { data } = await axios.get(
        `${url}/coin/get-added-coin/?userWalletAddress=${wallet}`
      );

      setcoinsAdded(data.data.length);
    };
    if (address) {
      coins(address);
    }
  }, [address]);
  return (
    <TableCell className={classes.tableCell} key={coinsAdded}>
      {coinsAdded}
    </TableCell>
  );
}

export default function CustomTable(props) {
  const { tableHead, tableData, tableHeaderColor, setloading, init, search } =
    props;
  var allArray = [];
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [allSelected, setAllSelected] = React.useState([]);
  const [allCheckTrue, SetallCheckTrue] = React.useState(false);
  const ticketFiner = async (account) => {
    return account;

    // const { ticketsOwned } = await cryptopartyContract.customers(account)
    // return ticketsOwned
  };

  const onSelectHandler = (e, value) => {
    if (e.target.checked) {
      setSelected([...selected, value]);
    } else {
      const array = selected.filter((item, i) => item !== value);
      setSelected([...array]);
    }
  };

  const SelectAllHandler = (e) => {
    if (e.target.checked) {
      setAllSelected([...allArray]);
    } else {
      setAllSelected([]);
      setSelected([]);
    }
  };

  React.useEffect(() => {
    if (allSelected.length > 0) {
      SetallCheckTrue(true);
    } else if (allSelected.length == 0) {
      SetallCheckTrue(false);
    }
  }, [allSelected]);
  return (
    <div className={classes.tableResponsive}>
      <Box className={classes.tableCell} style={{ position: "relative" }}>
        <span style={{ position: "absolute", top: "2.7rem", left: "1.5rem" }}>
          <ArrowDownwardIcon />
        </span>
        SELECT All AND CUSTOM TO SEND NOTIFICATION
        <BullkNotify
          ids={
            (allSelected.length > 0 && allSelected) ||
            (selected.length > 0 && selected)
          }
          init={init}
        />
      </Box>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              <TableCell>
                <Checkbox
                  color="secondary"
                  // indeterminate={numSelected > 0 && numSelected < rowCount}
                  // checked={selected.some()}
                  onChange={(e) => SelectAllHandler(e)}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
              {tableHead.map((prop, key) => {
                return (
                  <>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                      key={key}
                    >
                      {prop}
                    </TableCell>
                  </>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData?.length !== 0 ? (
            tableData.map(
              (
                {
                  _id,
                  addedAt,
                  address,
                  usedTickets,
                  notification,
                  coinsVoted,
                  productId,
                },
                key
              ) => (
                <>
                  {allArray.push(_id)}
                  <TableRow key={key} className={classes.tableBodyRow}>
                    <TableCell className={classes.tableCell} key={key}>
                      <Checkbox
                        color="secondary"
                        // indeterminate={numSelected > 0 && numSelected < rowCount}
                        // checked={selected?.find(id => id === _id) || allSelected.find(id => id === _id)}
                        checked={
                          (selected.length > 0 &&
                            selected.some((item) => item === _id)) ||
                          allCheckTrue
                        }
                        onChange={(e) => onSelectHandler(e, _id)}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} key={key}>
                      {_id}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={key}>
                      {address}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={key}>
                      <TableCellD account={address} />
                    </TableCell>

                    <TableCell className={classes.tableCell} key={key}>
                      {usedTickets}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={key}>
                      {notification.length}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textTransform: "capitalize" }}
                      key={key}
                    >
                      {coinsVoted.length}
                    </TableCell>
                    <CoinsAdd address={address} />

                    <TableCell className={classes.tableCell} key={key}>
                      <Link to={`/admin/view-notifications/${address}`}>
                        <IconButton>
                          <BiDetail />
                        </IconButton>
                      </Link>
                    </TableCell>
                    <TableCell className={classes.tableCell} key={key}>
                      <Notify id={_id} init={init} />
                    </TableCell>
                  </TableRow>
                </>
              )
            )
          ) : (
            <TableRow className={classes.tableBodyRow}>
              {!search ? (
                <TableCell
                  colSpan="7"
                  align="center"
                  className={classes.tableCell}
                >
                  <CircularProgress style={{ color: "#9931B1" }} />
                  <br />
                  No User active yet.
                </TableCell>
              ) : (
                <TableCell
                  colSpan="7"
                  align="center"
                  className={classes.tableCell}
                >
                  No User found with searched item "{search}".
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
