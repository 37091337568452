import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#000",
      light: "#FF772A",
      contrast: "#F3B930",
    },
    secondary: {
      main: "#FF0095",
      light: "#2BE233",
      contrast: "#F95E62",
    },
    text: {
      primary: "#fff",
      secondary: "#F3BA2F",
    },
  },
  typography: {
    fontFamily: "Open Sans",
    h1: {
      fontWeight: 900,
      fontSize: "90px",
    },
    h2: {
      fontWeight: 700,
      fontSize: "60px",
    },
    h3: {
      fontWeight: 400,
    },
  },
});

theme.overrides = {
  MuiCssBaseline: {
    "@global": {
      body: {
        fontFamily: "Open Sans",
        backgroundColor: "#000",
        color: "#ffffff",
      },
      ".img-fluid": {
        maxWidth: "100%",
        height: "auto",
      },
    },
  },
};

theme = responsiveFontSizes(theme);

export default theme;
